@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap);

/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Любые изменения этого файла будут потеряны при следующей компиляции.
 * Любое изменение проекта без возможности компиляции ДОЛЬШЕ И ДОРОЖЕ в 2-5 раз.
 */


.btn {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    max-width: 100%;
    margin: 0;
    border-radius: 10px;
    border: 0;
    padding: 12px 12px;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    font-size: 1em;
    font-family: inherit;
    line-height: 1.5;
    text-decoration: none;
    text-transform: none;
    color: #fff;
    background-color: #e6e6e6;
    background-image: none;
    cursor: pointer;
    overflow: hidden
}

.btn:hover, .btn:focus {
    text-decoration: none;
    color: #fff;
    background-color: #ccc
}

.btn:active, .btn:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(66, 208, 210, .7);
    box-shadow: 0 0 0 2px rgba(66, 208, 210, .7)
}

.btn--disabled, .btn:disabled {
    opacity: .7;
    cursor: not-allowed;
    color: #fff;
    background-color: #e6e6e6
}



a.btn--disabled {
    pointer-events: none
}

.form__fieldset {
    min-width: 0;
    margin: 0;
    border: 0;
    padding: 0
}

.form__fieldset:not(:last-child) {
    margin-bottom: 1em
}

.form__legend {
    display: block;
    width: 100%;
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 1em;
    font-size: 24px;
    font-family: "Blender Pro";
    font-weight: 700;
    line-height: 1.5;
    white-space: normal;
    color: currentColor
}

.logo {
    display: inline-block;
    white-space: nowrap
}

.logo img {
    display: block;
    width: 100%
}

@-ms-viewport {
    width: device-width
}

.page {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    min-width: 320px;
    min-height: 100%
}

.page__inner {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.page__content {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.page__footer-wrapper {
    -ms-flex-negative: 0;
    flex-shrink: 0
}

*, :before, :after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit
}

* {
    -webkit-tap-highlight-color: transparent
}

body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    min-height: 100%;
    word-break: break-word;
    font-family: 'Montserrat';
    font-size: 12px;
    line-height: 1.5;
    text-align: left;
    color: #fff;
    background-color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000;
    background-image: url(../img/lines.svg);
    background-size: 100% 20px;
    background-repeat-x: no-repeat;
    background-repeat-y: repeat;
    position: relative
}

@font-face {
    font-family: 'Blender Pro';
    src: local("../fonts/Blender Pro Bold"), local("Blender-Pro-Bold"), url(../fonts/BlenderPro-Bold.woff2) format("woff2"), url(../fonts/BlenderPro-Bold.woff) format("woff"), url(../fonts/BlenderPro-Bold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: 'Blender Pro';
    src: local("../fonts/Blender Pro Book"), local("Blender-Pro-Book"), url(../fonts/BlenderPro-Book.woff2) format("woff2"), url(../fonts/BlenderPro-Book.woff) format("woff"), url(../fonts/BlenderPro-Book.ttf) format("truetype");
    font-weight: 400;
    font-style: normal
}

[tabindex='-1']:focus {
    outline: 0 !important
}

[role=button] {
    cursor: pointer
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

a, button, [role=button], input, label, select, textarea {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    margin: 0
}

button, input {
    overflow: visible;
    text-transform: none
}

footer {
    background-color: #141414
}

.btn {
    font-family: 'Blender Pro';
    text-transform: uppercase;
    font-weight: 700;
    background-color: #42d0d2;
    font-size: 14px;
    line-height: 40px
}

.btn_fix {
    margin-left: -18px
}

.btn_deact {
    background-color: transparent
}

.btn_deact img {
    opacity: .3
}

.box {
    border: 1px solid #42d0d2;
    padding: 20px;
    position: relative
}

.box .sub {
    font-size: 20px;
    font-family: 'Roboto Condensed';
    text-transform: uppercase
}

.box .text {
    font-size: 12px
}

.box .first {
    margin-left: -40px
}

.box__alert {
    background-color: #42d0d2;
    padding: 15px;
    color: #fff;
    margin-top: -25px;
    margin-left: 25px;
    position: relative
}

.box__alert .sub {
    font-size: 20px;
    font-family: 'Roboto Condensed';
    text-transform: uppercase
}

.box__alert .text {
    font-size: 12px
}

.chart__title {
    font-size: 10px;
    font-weight: 700
}

.chart {
    border-bottom: 3px solid #fff;
    height: 100px
}

.chart .row {
    height: inherit
}

.chart__col {
    background: -webkit-gradient(linear, left top, left bottom, from(#42d0d294), to(rgba(66, 208, 210, 0)));
    background: linear-gradient(180deg, #42d0d294 0, rgba(66, 208, 210, 0) 100%);
    width: 40px;
    margin: auto;
    min-height: 90px;
    border-top: 3px solid #42d0d2;
    position: absolute;
    margin-top: 10px;
    font-weight: 700;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto
}

.chart__col_2 {
    min-height: 60px
}

.chart__col_3 {
    min-height: 40px
}

.box_with-alert {
    margin-bottom: 150px
}

.col_right {
    position: absolute;
    right: -20px
}

.table td, .table th {
    border-top: 1px solid #42d0d259
}

.mini-title {
    text-transform: uppercase;
    font-family: 'Roboto Condensed'
}

.form-control.numbs {
    background-color: transparent;
    color: #fff;
    width: 60px;
    display: inline-block;
    margin-right: -34px;
    margin-top: 0;
    border: 0;
    font-weight: 700;
    font-family: 'Roboto Condensed';
    padding-right: 0
}

.news__img {
    height: 200px;
    width: 100%;
    background-size: cover;
    position: relative
}

.cover {
    position: relative;
    width: calc(100% - 20px);
    min-width: 110px;
    max-width: 170px;
    height: 40px;
    line-height: 40px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border-top: 1px solid #42d0d2;
    border-bottom: 1px solid #42d0d2;
    border-left: 1px solid #42d0d2;
    -webkit-transform-origin: 50% 100% 0;
    transform-origin: 50% 100% 0;
    margin: 0 12px;
    margin-left: 0
}

.box_info {
    border-left: none;
    border-right: none;
    min-height: 120px
}

.box_info.cover_box {
    min-height: 50px;
    height: auto
}

.cover_box {
    width: 100%;
    max-width: initial;
    line-height: 18px;
    text-align: left;
    padding: 10px 0;
    padding-right: 0;
    min-height: auto
}

.btn-icon:hover img {
    opacity: 1
}

.btn-icon {
    margin-top: -1px;
    border-right: 1px solid #42d0d2
}

.box_full {
    min-height: calc(100vh - 150px);
    overflow-y: auto;
    max-height: calc(100vh - 150px);
    position: relative;
}

.btn:hover, .btn:focus {
    color: #fff;
    background-color: #42d0d2;
}

.box_alert {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 20px;
    opacity: .6
}

.box_games {
    min-height: calc(100vh - 150px)
}

.box_scroll {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
}

.cover::after, .cover-btn::before, .cover_btn::before {
    position: absolute;
    content: '';
    width: 15px;
    height: 26px
}

.cover_left {
    padding-right: 12px
}

.cover_left:after {
    content: none
}

.cover_right:before {
    content: none
}

.white {
    color: #fff
}

.btn_cover {
    width: calc(100% - 25px);
    margin-left: 10px
}

.btn_small {
    max-width: 90px
}

.cover_btn {
    background-color: #42d0d2;
    font-family: 'Blender Pro';
    text-transform: uppercase;
    opacity: 1;
    z-index: 2
}

.cover_btn .left, .cover_btn .right {
    background-color: #42d0d2
}

.cover_btn::before, .cover_btn::after {
    background-color: #42d0d2
}

.btn-icon {
    padding: 0 11px
}

.btn-icon img {
    height: 20px
}

.cover::after {
    right: -15px;
    top: -1px;
    border-top: 1px solid #42d0d2;
    border-right: 1px solid #42d0d2
}

.corner {
    position: absolute;
    width: 21px;
    height: 21px;
    -webkit-transform: 50% 50% 0;
    transform: 50% 50% 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.cover_box:after {
    height: calc(100% - 13px)
}

.left {
    top: 3px;
    left: -11px;
    border-left: 1px solid #42d0d2
}

.right {
    bottom: 3px;
    right: -11px;
    border-right: 1px solid #42d0d2
}

button, html [type=button], [type=reset], [type=submit] {
    -webkit-appearance: button
}

button::-moz-focus-inner, [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=date], input[type=time], input[type=datetime-local], input[type=month] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

svg {
    fill: currentColor
}

a svg, button svg {
    pointer-events: none
}

svg:not(:root) {
    overflow: hidden
}

img {
    max-width: 100%;
    height: auto
}

a {
    color: #42d0d2;
    background-color: transparent;
    -webkit-transition: color .3s;
    transition: color .3s
}

a:hover, a:focus {
    color: #269fa1
}

table.rating-table tr td {
    display: table-cell
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    margin-top: 2em;
    margin-bottom: 2em;
    border: 0;
    border-top: 1px solid rgba(225, 224, 242, .1)
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-family: "Blender Pro";
    font-weight: 400;
    line-height: 1.2;
    color: currentColor
}

h1 {
    font-size: 40px
}

h2 {
    font-size: 32px
}

h3 {
    font-size: 24px
}

h4 {
    font-size: 16px
}

h5 {
    font-size: 16px
}

h6 {
    font-size: 14px
}

p, ul, ol, dl, table, blockquote, pre, address, figure {
    margin-top: 0;
    margin-bottom: 1em
}

p {
    margin-bottom: 0;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    hyphenate-limit-chars: 6 3 3;
    hyphenate-limit-lines: 2;
    hyphenate-limit-last: always;
    hyphenate-limit-zone: 8%
}

address {
    font-style: normal
}

ul, ol {
    margin-bottom: 1em;
    padding-left: 1.5em
}

ul ul, ul ol, ol ul, ol ol {
    margin-top: 0;
    margin-bottom: 0
}

li {
    line-height: inherit
}

small {
    font-size: .75em
}

sub, sup {
    font-size: .75em
}

b, strong {
    font-weight: bolder
}

blockquote {
    padding: 0;
    margin-left: 0;
    margin-right: 0
}

code, kbd, pre {
    font-family: "Montserrat"
}

code {
    padding: .06em .3em;
    color: #fff;
    background-color: rgba(0, 0, 0, .08);
    border-radius: 10px
}

kbd {
    padding: .06em .3em;
    color: #fff;
    background-color: #e6e6e6;
    border-radius: 10px
}

kbd kbd {
    padding: 0
}

pre {
    display: block;
    width: 100%;
    margin-bottom: 1em;
    overflow-x: auto;
    -moz-tab-size: 2;
    -o-tab-size: 2;
    tab-size: 2;
    background-color: rgba(0, 0, 0, .08)
}

pre code {
    background: 0 0
}

body {
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden
}

h1 {
    font-size: 32px
}

a {
    display: inline-block;
    color: inherit;
    text-decoration: none
}

.subtitle {
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Blender Pro';
    font-size: 24px
}

#demo li {
    -webkit-text-stroke: 1px #42d0d2;
    font-size: 80px;
    line-height: 24px;
    font-family: 'Blender Pro';
    color: transparent
}

#demo li .small {
    font-family: Roboto;
    font-size: 16px;
    -webkit-text-stroke: 0 #42d0d2;
    color: #000
}

#demo li.mini {
    font-size: 50px;
    line-height: 20px
}

.black {
    color: #fff
}

.black #demo li .small {
    color: #fff
}

h3 {
    font-size: 34px;
    font-weight: 400
}

h4 {
    font-size: 18px;
    font-weight: 700
}

.text {
    font-size: 14px
}

li, .sum {
    list-style: none;
    font-size: 22px;
    font-family: 'Blender Pro';
    font-weight: 700;
    margin-bottom: 6px
}

li .point, .sum .point {
    background-color: #42d0d2;
    padding: 0 14px;
    margin-right: 5px
}

li .value, .sum .value {
    font-size: 12px
}

.box__alert .sum {
    margin-bottom: 0;
    line-height: 15px;
    margin-top: 5px
}

.sum_big {
    color: #42d0d2;
    font-size: 36px;
    line-height: 24px
}

.sum_big .value {
    font-size: 18px;
    color: #fff
}

.act {
    color: #42d0d2
}

.bb {
    border-bottom: 1px solid #42d0d259
}

.inline-text__date {
    font-size: 12px;
    color: rgba(255, 255, 255, .3);
    font-weight: 400
}

.achvs {
    background: radial-gradient(50% 50% at 50% 50%, rgba(66, 208, 210, 0) 24.48%, rgba(66, 208, 210, .24) 100%)
}

.sum_light {
    font-weight: 400
}

.sum_small {
    font-size: 14px;
    margin-bottom: 0
}

.sum_small .value {
    font-size: 10px
}

.btns_right {
    margin-right: -30px
}

.title_big {
    font-family: "Blender Pro";
    font-size: 44px;
    font-weight: 400;
    text-transform: uppercase
}

a:hover {
    color: inherit;
    text-decoration: none
}

.modal-window {
    position: fixed;
    background-color: rgba(255, 255, 255, .25);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all .3s;
    transition: all .3s
}

.modal-window:target {
    visibility: visible;
    opacity: 1;
    pointer-events: auto
}

.modal-window > div {
    max-width: 500px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 2em;
    background: #fff
}

.modal-window header {
    font-weight: 700
}

.modal-window h1 {
    font-size: 150%;
    margin: 0 0 15px
}

.modal-close {
    color: #aaa;
    line-height: 50px;
    font-size: 24px;
    position: absolute;
    right: -10px;
    text-align: center;
    top: 0;
    width: 70px;
    text-decoration: none
}

.modal-close:hover {
    color: #000
}

a {
    color: inherit
}

.modal-window div:not(:last-of-type) {
    margin-bottom: 15px
}

small {
    color: #aaa
}

.dots {
    background-image: url(../img/dots.svg);
    background-repeat: no-repeat;
    width: 140px;
    height: 120px;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    margin: auto
}

.dots_2 {
    left: 90px;
    top: -45px;
    right: initial
}

.line {
    width: 150px;
    height: 2px;
    background-color: #42d0d2;
    position: absolute;
    left: 0
}

.line_2 {
    height: 4px;
    width: 75px
}

.page__content {
    position: relative
}

.robot {
    min-height: 100vh;
    background-image: url(../img/robo.png);
    background-size: contain;
    background-position-x: right;
    background-position-y: bottom;
    background-repeat: no-repeat;
    position: fixed;
    bottom: 0;
    right: -10%;
    width: 75%;
    z-index: 2
}

.absolute {
    position: relative;
    height: 20px;
    width: 100%
}

.main {
    min-height: 100vh
}

.block {
    padding: 50px 20px 20px 0
}

.block_white {
    background-color: #fff;
    color: #000;
    background-repeat-y: repeat;
    position: relative
}

.block__shape {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -79px;
    width: 80px;
    height: 100%;
    background-image: url(../img/shape.svg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover
}

.block__shape_2 {
    background: 0 0;
    left: initial;
    right: -300px;
    width: 300px;
    background-color: #fff
}

.block__shape_modal {
    width: 40px;
    left: -40px;
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1)
}

.row_full {
    min-height: 100vh
}

.login {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 36px;
    position: relative
}

.login::before {
    content: '';
    position: absolute;
    width: 11px;
    height: 1px;
    top: 70px;
    background-color: rgba(255, 255, 255, .3)
}

.question {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative
}

.question::before {
    content: '';
    position: absolute;
    width: 11px;
    height: 1px;
    top: 50px;
    background-color: rgba(255, 255, 255, .3)
}

.wrapper-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    padding: 0 50px
}

.wrapper-form_full-height {
    height: 100vh
}

.form__title {
    font-size: 36px;
    line-height: 44px;
    color: #fff;
    margin-bottom: 15px
}

.form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 480px;
    background: #27273f;
    padding: 30px;
    border-radius: 10px
}

.form label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 12px;
    line-height: 10px;
    color: rgba(225, 224, 242, .8);
    margin-bottom: 15px
}

.label_form {
    font-size: 12px;
    line-height: 10px;
    color: rgba(225, 224, 242, .8);
    margin-bottom: 10px
}

.img_inline {
    margin-right: 10px
}

.form input {
    height: 35px;
    margin-top: 8px;
    background: #fff;
    border: 0;
    border-radius: 5px;
    font-size: 12px;
    line-height: 15px;
    color: #000;
    padding: 10px 14px;
    margin-bottom: 5px
}

.form__btn {
    background: #0063ff;
    border-radius: 5px;
    border: 0;
    height: 39px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #fff;
    width: 100%
}

.form__error-message {
    color: #be3b63;
    font-size: 12px
}

.form__label-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.form__label-wrap-img {
    margin-left: 5px
}

.nopadding {
    padding: 0 !important;
    margin: 0 !important
}

.content-block {
    border-radius: 10px;
    height: auto;
    overflow-x: hidden
}

.content-block__container {
    width: calc(200% + 30px);
    padding: 0;
    -webkit-transition: all ease 500ms;
    transition: all ease 500ms;
    overflow: hidden
}

.content-block__container_active {
    -webkit-transform: translate3d(calc(-50% - 15px), 0, 0);
    transform: translate3d(calc(-50% - 15px), 0, 0)
}

.title_success {
    color: #51cb20
}

.error {
    color: #d7263d
}

.title_small, .text_small {
    font-weight: 400;
    font-size: 12px
}

.title_no-margin {
    margin-top: 0
}

.title_middle {
    font-size: 16px
}

.title_dark {
    color: #27273f
}

.title_inline, .text_inline {
    display: inline-block;
    font-size: 14px
}

.text_red {
    color: #d7263d
}

.text_gray {
    color: rgba(225, 224, 242, .2)
}

.content_bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.content_long {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.link_mg-left {
    margin-left: 15px
}

.link_nav {
    text-transform: uppercase;
    font-weight: 700
}

.link_error {
    color: #d7263d
}

.link__text {
    margin-left: 5px
}

.link_bordered {
    border-bottom: 1px solid rgba(225, 224, 242, .1);
    padding-bottom: 5px;
    width: 100%
}

.coin {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 7px
}

.coin_bit {
    background: #ea8423;
    -webkit-box-shadow: 0 4px 14px rgba(234, 132, 35, .5);
    box-shadow: 0 4px 14px rgba(234, 132, 35, .5);
    margin-right: 10px
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.flex.flex_align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.flex.flex_end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.flex_buttons {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.inline-text__title {
    color: #42d0d2;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 14px;
    line-height: 22px;
    font-weight: 700
}

.dots_3 {
    left: initial
}

.dots_4 {
    right: initial;
    top: 0;
    bottom: 0;
    left: -110px
}

.col-z-index {
    z-index: 3
}

.inline-text__subtitle {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 14px
}

.inline-text__title_small {
    font-size: 10px
}

.inline-text_subtitle {
    color: #5d5973;
    font-size: 10px
}

.inline-text__text {
    color: #fff
}

.inline-text__text_small {
    font-size: 10px
}

.text_upper {
    text-transform: uppercase
}

.text_tooltip {
    display: inline-block;
    position: relative;
    cursor: pointer
}

.text_tooltip:hover:after {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #6c757d;
    border-radius: 5px;
    color: #fff;
    content: attr(title);
    margin: -27px auto 0;
    font-size: 12px;
    padding: 5px 10px;
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap
}

.text_tooltip:hover:before {
    border: solid;
    border-color: #6c757d transparent;
    border-width: 8px 5px 0 6px;
    border-radius: 0;
    content: "";
    left: 10px;
    bottom: 12px;
    position: absolute
}

.table {
    font-family: 'Roboto Condensed', sans-serif
}

.subtitle_middle {
    font-size: 14px;
    font-weight: 700
}

.subtitle_green {
    color: #51cb20
}

.subtitle_orange {
    color: #ea8423
}

.subtitle_red {
    color: #d7263d
}

.cell_middle, table td.cell_middle {
    vertical-align: middle
}

.title_mini {
    font-family: 'Blender Pro';
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700
}

.title_form {
    text-transform: uppercase;
    margin-bottom: 10px
}

.title_error {
    color: #d7263d
}

.hr_fullwidth {
    width: calc(100% + 60px);
    margin-left: -30px;
    margin-right: -30px
}

.hr_title {
    margin-top: 10px
}

.img_text {
    margin: 0 3px;
    padding-bottom: 2px
}

.img_left {
    margin-left: 0;
    margin-right: 3px
}

.ipo {
    background-color: #fff;
    border-radius: 5px;
    padding: 8px 4px;
    margin-right: 10px;
    max-height: 36px;
    width: 90px;
    text-align: center
}

.ipo img {
    max-height: 22px;
    width: auto;
    padding: 0 5px
}

.cell_bottom, table td.cell_bottom {
    vertical-align: bottom
}

table td.cell_icon-button {
    vertical-align: middle
}

.subtitle_light {
    font-weight: 400
}

.title_inline, .subtitle_inline {
    display: inline-block;
    margin-right: 5px
}

.title_margin-small, .subtitle_margin-small, .text_margin-small {
    margin-bottom: 5px
}

.title_margin-md, .subtitle_margin-md, .text_margin-md {
    margin-bottom: 10px
}

.title_margin-mini, .subtitle_margin-mini, .text_margin-mini {
    margin-bottom: 3px
}

.button, a.button, button {
    background-color: transparent;
    color: #3bd798;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    -webkit-transition: all ease 150ms;
    transition: all ease 150ms;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0
}

.button:hover {
    background-color: #3bd798;
    color: #fff
}

.button:focus {
    outline: 0
}

.button.button_blue {
    background-color: rgba(0, 99, 255, .3);
    color: #0063ff
}

.button.button_blue:hover {
    background-color: #0063ff;
    color: #fff
}

.button_first {
    margin-right: 10px
}

.button_flex {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.icon-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all ease 150ms;
    transition: all ease 150ms
}

.icon-button_active {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.icon-button_bottom {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.item {
    padding: 15px 0
}

.item:not(:first-child) {
    border-top: 1px solid rgba(225, 224, 242, .1)
}

.inline-text {
    color: #fff
}

.inline-text__title_state {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    display: inline-block;
    position: relative;
    margin-right: 5px
}

.inline-text__indicator {
    height: 10px;
    width: 25px;
    position: absolute;
    left: -30px;
    top: 0;
    bottom: 0;
    margin: auto
}

.inline-text__indicator_success {
    background-color: #51cb20
}

.inline-text__indicator_wait {
    background-color: #ea8423
}

.inline-text__indicator_error {
    background-color: #d7263d
}

.inline-text__indicator_progress {
    background-color: #42d0d2
}

.inline-text__title_state-success, .text_green {
    color: #51cb20
}

.inline-text__title_state-progress {
    color: #42d0d2
}

.inline-text__title_state-wait {
    color: #ea8423
}

.inline-text__title_state-error {
    color: #d7263d
}

.img-block {
    margin-right: 5px
}

.img-block__cover {
    background-color: rgba(225, 225, 225, .1);
    border-radius: 5px;
    padding: 6px
}

.img-block__image {
    background-color: #fff;
    border-radius: 5px;
    padding: 3px
}

.flex.flex_mg-top {
    margin-top: 6px
}

.form_no-padded, .main__form_no-padded {
    padding: 0
}

.atabs {
    border-radius: 8px;
    overflow: hidden;
    -webkit-box-shadow: 0 4px 4px -2px rgba(0, 0, 0, .5);
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, .5)
}

.atab {
    width: 100%;
    color: #000;
    overflow: hidden;
    margin-bottom: 1em;
    border-radius: 5px
}

.atab-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1em;
    background: #fff;
    font-weight: 700;
    border-bottom: 1px solid #27273f1a;
    cursor: pointer
}

.atab-label:hover {
    background: #e6e6e6
}

.atab-label::after {
    content: "\276F";
    width: 1em;
    height: 1.4em;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    text-align: center;
    -webkit-transition: all .35s;
    transition: all .35s
}

.atab-label_dark {
    background: initial;
    padding: 0;
    border-bottom: none
}

.atab-label_dark:hover {
    background: #e6e6e6
}

.atab-label_dark-label .atab-label_dark::after {
    content: "\276F";
    color: #42d0d2;
    width: 1em;
    height: 1em;
    text-align: center;
    -webkit-transition: all .35s;
    transition: all .35s
}

.atab-content {
    max-height: 0;
    padding: 0 1em;
    color: #000;
    background: #fff;
    -webkit-transition: all .35s;
    transition: all .35s
}

.atab-content_dark {
    background: 0 0
}

.atab-close {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1em;
    font-size: .75em;
    background: #fff;
    cursor: pointer
}

.atab-close:hover {
    background: #e6e6e6
}

.title_light {
    font-weight: 400
}

.row_slided-left {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    -webkit-transition: all ease 300ms;
    transition: all ease 300ms;
    opacity: 0
}

.slick-slide {
    padding: 0px 15px;
}

.link {
    cursor: pointer
}

input.itab {
    display: none
}

input.itab:checked + .atab-label {
    color: #42d0d2
}

input.itab:checked + .atab-label::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

input.itab:checked ~ .atab-content {
    max-height: 100vh;
    padding: 1em;
    border-bottom: 1px solid #27273f1a
}

.input-group-text {
    border-radius: 0 !important
}

.btn_disabled {
    background-color: #42d0d252;
    pointer-events: none
}

.btn_disabled img {
    opacity: .6
}

.table-game {
    max-height: auto
}

.filter {
    background-color: #42d0d230;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    height: 100%;
    width: 100%
}

.relative {
    position: relative
}

.relative.img {
    height: 250px;
    width: 100%;
    background-size: cover
}

.robot_absolute {
    position: absolute;
    right: -15%
}

.inline-text__date_normal {
    font-size: 14px
}

.disabled input {
    pointer-events: none
}

.content-block_dark {
    z-index: 3;
    position: relative
}

.black.modal-window > div {
    color: #fff
}

.modal-window {
    position: fixed;
    background-color: #00000075;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all .3s;
    transition: all .3s;
    color: #000
}

.modal-window:target {
    visibility: visible;
    opacity: 1;
    pointer-events: auto
}

.modal-window > div.big {
    background-color: #000;
    color: #fff;
    border: 1px solid #42d0d2;
    max-width: 1000px;
    width: 100%
}

.modal-window > div.modal_big {
    background-color: #000;
    color: #fff;
    border: 1px solid #42d0d2;
    max-width: 100vw;
    width: 100%
}

.modal-window > div {
    max-width: 500px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 2em;
    background: #fff;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto
}

.modal-window header {
    font-weight: 700
}

.modal-window h1 {
    font-size: 150%;
    margin: 0 0 15px
}

td .inline-text__subtitle {
    /* max-width: 110px */
}

td img.mr-2 {
    max-width: 44px;
    max-height: 44px
}

small {
    color: #aaa
}

.text_dark {
    color: #27273f60
}

.text_darkest {
    color: #000
}

.text_bigger {
    font-size: 14px
}

.label_dark {
    color: #000
}

.text_mini {
    font-size: 10px
}

.hr_nav {
    width: 10px;
    border-top: 1px solid rgba(225, 224, 242, .2);
    margin-top: 1em;
    margin-bottom: 1em
}

.tabs--ipo .tabs__links {
    position: absolute;
    top: 28px
}

.data-select {
    background-color: initial;
    color: rgba(225, 224, 242, .8);
    font-size: 12px;
    border: 0;
    height: 25px;
    padding: 0;
    -moz-text-align-last: right;
    text-align-last: right;
    padding-right: 5px;
    cursor: pointer;
    -webkit-transition: all ease 150ms;
    transition: all ease 150ms
}

.tabs--mini .tabs__link-wrap {
    font-size: 9px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0px
}

.data-select:focus {
    background-color: initial;
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none
}

.table td .cell-info, .table th .cell-info {
    padding: 0;
    border: 0;
    -webkit-transition: all ease 300ms;
    transition: all ease 300ms
}

.cell-info__text {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: all ease 300ms;
    transition: all ease 300ms;
    padding: 0;
    border-top: 1px solid transparent;
    opacity: 0
}

.cell-info__text--active {
    max-height: 100vh;
    padding: 15px 0;
    border-top: 1px solid rgba(225, 224, 242, .1);
    opacity: 1
}

.click {
    cursor: pointer
}

.nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.apexcharts-gridline {
    opacity: .1
}

::-webkit-scrollbar {
    width: 9px
}

::-webkit-scrollbar-track {
    background-color: #1c1c1c;
    border-radius: 10px
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    padding: 0 1px;
    background-color: #464848;
    border: 1px solid #1c1c1c
}

.coin_mg {
    margin: -8px -4px -18px -4px
}

.apexcharts-legend-text {
    color: #fff !important
}

.apexcharts-text.apexcharts-xaxis-label {
    color: #fff !important
}

.subtitle_code {
    font-size: 12px;
    font-weight: 600;
    text-transform: initial
}

.nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-right: 20px
}

.nav-item:not(:last-child) {
    margin-right: 15px
}

.hr_nav {
    display: none
}

.page-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.header-gutter {
    padding-top: 115px
}

.panel.col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
}

.panel {
    max-height: 100px
}

.inline-text__code {
    color: #fff;
    font-size: 10px;
    line-height: 12px
}

.flex_code {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.form-control {
    border-radius: 0
}

p {
    font-size: 16px
}

.btn.btn-prime {
    background-image: url(../img/btn.svg);
    height: 50px;
    width: 255px;
    border-radius: 0;
    background-color: transparent;
    font-family: Blender Pro;
    font-weight: 700;
    font-size: 18px;
    background-size: cover;
    color: #fff;
    padding: 12px;
    position: relative;
    overflow: visible;
    line-height: 26px
}

.btn {
    border-radius: 0;
    min-height: 40px
}

p {
    position: relative
}

.btn.btn-prime:hover {
    color: #fff
}

.btn-lines {
    position: absolute;
    top: -15px;
    bottom: 0;
    right: 0;
    left: -30px;
    margin: auto;
    width: 120%;
    height: 90px;
    background-image: url(../img/btn-lines.svg);
    background-size: contain;
    z-index: 0;
    background-repeat: no-repeat;
    background-position: center
}

.line {
    bottom: initial;
    top: 0
}

.modal-window {
    position: fixed;
    background-color: rgba(0, 0, 0, .75);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all .3s;
    transition: all .3s
}

.modal-window:target {
    visibility: visible;
    opacity: 1;
    pointer-events: auto
}

.modal-window > div {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 2em;
    background: #fff
}

.modal-window header {
    font-weight: 700
}

.modal-window h1 {
    font-size: 150%;
    margin: 0 0 15px
}

.news {
    font-family: 'Blender Pro'
}

.container {
    max-width: 1210px;
    margin: 0 auto;
    padding: 0 15px
}

.news__title {
    font-weight: 300;
    font-size: 50px;
    line-height: 50px;
}

.news img {
    margin-bottom: 13px
}

.news__item p {
    font-weight: 400;
    line-height: 20px
}

.news__data {
    font-family: Roboto Condensed;
    font-weight: 700;
    font-size: 14px;
    line-height: 172.6%;
    color: #fff
}

.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.news__caption {
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    text-transform: uppercase
}

.news p {
    font-family: 'Roboto Condensed';
    font-weight: 300;
    font-size: 14px;
    line-height: 20px
}

.table-header {
    display: none
}

a {
    color: #42d0d2;
    opacity: .8;
    -webkit-transition: all ease 150ms;
    transition: all ease 150ms
}

a:hover {
    color: #42d0d2;
    opacity: 1
}

a.btn {
    opacity: 1
}

small {
    color: #aaa
}

.block__shape_2 {
    display: none
}

.btn.btn-prime.btn-sml {
    height: 40px;
    font-size: 16px;
    line-height: 16px
}

.box_balance {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 60px
}

.box_balance::after {
    content: '';
    background-image: url(../img/rec-1.svg);
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: calc(100% + 2px);
    height: 30px;
    position: absolute;
    left: -1px;
    bottom: -30px
}

.box_prizes {
    width: calc(100% - 22px);
    border-right: 0;
    padding-right: 0
}

.table.black {
    color: #000
}

.table.black .active {
    color: #fff
}

.table.black .title_mini {
    color: #000
}

.sum_data {
    line-height: 18px
}

.box .d-flex {
    padding: 7px 0
}

.box_prizes::after {
    content: '';
    background-image: url(../img/rec-2.svg);
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 22px;
    height: calc(100% + 2px);
    position: absolute;
    right: -22px;
    bottom: -1px
}

.box_rating::before {
    content: '';
    background-image: url(../img/rec-3.svg);
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 10px;
    height: calc(100% + 2px);
    position: absolute;
    left: -10px;
    bottom: -1px
}

.box_rating {
    border-left: 0;
    border-right: 0;
    width: calc(100% - 48px)
}

.box_rating::after {
    content: '';
    background-image: url(../img/rec-4.svg);
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 18px;
    height: calc(100% + 2px);
    position: absolute;
    right: -18px;
    bottom: -1px
}

.link {
    color: #ffffff8a !important;
    font-weight: 700;
    opacity: 1;
    margin-top: 5px;
    border-bottom: 2px solid #fff
}

.link-register {
    text-transform: uppercase;
    color: #aaa;
    font-weight: 700;
    opacity: 1;
    margin-top: 5px;
    font-family: Blender Pro;
    font-size: 14px;
    border-bottom: 2px solid #42d0d2;
    min-width: 130px;
}

.link-register:hover {
    color: #42d0d2;
}

@media screen and (max-width: 768px) {
    .modal-window > div {
        padding-top: 2rem;
    }

    .modal-close {
        top: 1rem;
    }
}

@media (max-width: 425px) {
    .link-register {
        margin-top: 5%;
    }
}

.link:hover {
    color: #fff !important
}

.text_small {
    font-size: 12px
}

.dropbtn {
    color: #fff;
    padding: 10px;
    font-family: 'Blender Pro';
    font-weight: 700;
    font-size: 14px;
    border: 0;
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden
}

.img-cover {
    background-image: url(../img/img-cover.svg);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto
}

.stock-img {
    padding: 7px
}

.dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 100px;
    z-index: 5;
    top: 40px
}

.dropdown-content a {
    color: #000;
    padding: 12px 16px;
    text-decoration: none;
    display: block
}

.dropdown-content a:hover {
    color: #42d0d2
}

.dropdown:hover .dropdown-content {
    display: block
}

.number {
    display: inline-block;
    position: relative;
    min-width: 80px;
    float: left;
}

.number input[type=number] {
    display: block;
    height: 39px;
    line-height: 32px;
    width: 100%;
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
    padding-left: 26px
}

.number input[type=number]::-webkit-outer-spin-button, .number input[type=number]::-webkit-inner-spin-button {
    display: none
}

.number-minus {
    position: absolute;
    left: 3px;
    bottom: 5px;
    width: 20px;
    height: 15px;
    padding: 0;
    display: block;
    text-align: center;
    border: 0;
    border-right: 1px solid #ddd;
    font-size: 16px;
    font-weight: 600;
    background-image: url(../img/stock-down.svg);
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
    opacity: .4
}

.cover-btn {
    display: inline-block;
    border-top: 1px solid #42d0d2;
    border-bottom: 1px solid #42d0d2;
    -webkit-transform-origin: 50% 100% 0;
    transform-origin: 50% 100% 0;
    height: 39px;
    width: 27px;
    margin-left: -1px;
    background-color: #42d0d2;
    opacity: 0
}

.cover-btn::before, .cover_btn::before {
    left: -15px;
    bottom: -1px;
    background-color: #42d0d2;
    border-left: 1px solid #42d0d2;
    border-bottom: 1px solid #42d0d2
}

.cover-btn {
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease
}

.cover-btn .left {
    background-color: #42d0d2;
    top: 4px;
    left: -11px
}

.cover-btn:hover {
    opacity: 1
}

.cover-btn__img {
    margin-left: -14px;
    opacity: 1;
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: -3px;
    opacity: .3
}

.btn_left {
    background-color: transparent;
    padding: 0;
    height: 39px;
    margin-left: 0;
    background-color: tranparent;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    overflow: visible;
    max-height: 39px
}

.btn-icon {
    float: left
}

.btn_left:hover, .btn_left:focus {
    background-color: transparent;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn_left:hover .cover-btn:hover, .btn_left:focus .cover-btn:hover {
    opacity: 1
}

.number-plus {
    position: absolute;
    top: 5px;
    left: 3px;
    width: 20px;
    height: 15px;
    padding: 0;
    display: block;
    text-align: center;
    border: 0;
    border-left: 1px solid #ddd;
    font-size: 16px;
    font-weight: 600;
    background-image: url(../img/stock-up.svg);
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
    opacity: .4;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease
}

.number-plus:hover, .number-plus:focus, .number-minus:hover, .number-minus:focus {
    opacity: 1;
    outline: 0;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.sum_large {
    font-size: 45px;
    line-height: 55px
}

.sum_large {
    top: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: auto;
    font-family: 'Blender Pro'
}

.rules-text {
    max-width: 200px
}

.bad {
    background: radial-gradient(50% 50% at 50% 50%, rgba(215, 38, 61, 0) 24.48%, rgba(215, 38, 61, .24) 100%)
}

.bad .act, .bad .sum.sum_big, .bad b {
    color: #d7263d
}

.chart-data {
    text-align: right;
    width: 102px;
    height: 14px;
    padding: 0;
    z-index: 999;
    float: right;
    margin: auto;
    /* margin: 0; */

    /* top: 75px; */
    /* right: 0;
    z-index: 999;
    width: 100px;
    text-align: right;
    padding: 0;
    height: 14px; */
}

.chart-label {
    width: 18px;
    height: 100%;
    background-size: 87%;
    /*background-position: center;*/
    background-position: 0 220px;
    background-image: url(../img/chart-label.svg);
    position: absolute;
    left: -10px;
    background-repeat: no-repeat;
}

.btn {
    position: relative;
    z-index: 1
}

.btn-circle-1 {
    position: absolute;
    left: -18px;
    bottom: -18px;
    width: 38px;
    height: 38px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../img/circle-1.svg);
    z-index: 0
}

.btn-circle-2 {
    position: absolute;
    right: -20px;
    top: 0;
    width: 60px;
    height: 60px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../img/circle-2.svg);
    z-index: 0
}

.btn-circle-1 {
    -webkit-animation: rotating 10s infinite linear;
    animation: rotating 10s infinite linear
}

.btn-circle-2 {
    -webkit-animation: rotating 15s infinite linear;
    animation: rotating 15s infinite linear
}

@-webkit-keyframes rotating {
    to {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes rotating {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.btn-box {
    position: relative;
    margin-left: 30px;
    display: inline-block
}

.btn-box.disabled {
    margin-left: 0
}

.btn-box.disabled .btn-circle-1, .btn-box.disabled .btn-circle-2 {
    opacity: 0
}

.table {
    display: table;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 0;
    border: 0;
    color: #fff
}

.table td, .table th {
    padding: 12px 12px;
    vertical-align: middle;
    border-top: 1px solid #42d0d259;
    border-bottom: none
}

.table td:not(:first-child), .table th:not(:first-child) {
    border-left: 1px solid rgba(225, 224, 242, .1);
    border-right: none;
    border-left: none;
    padding: 10px 3px
}

.table td:first-child {
    padding: 10px 0;
    padding-left: 0
}

.table td.cell-info {
    padding: 0;
    border: 0
}

.table td:last-child {
    padding-right: 0
}

.table thead td {
    border-top: 0
}

.table tr.active {
    background-color: #42d0d2
}

.table th {
    text-align: inherit
}

.table table td {
    padding-right: 10px
}

/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Любые изменения этого файла будут потеряны при следующей компиляции.
 * Любое изменение проекта без возможности компиляции ДОЛЬШЕ И ДОРОЖЕ в 2-5 раз.
 */
@media (min-width: 576px) {
    .d-sm-table-header-group {
        display: table-header-group !important
    }

    .title_big {
        font-size: 64px
    }
}

@media (min-width: 768px) {
    .btn_main {
        margin-left: -15%
    }

    h1 {
        font-size: 45px
    }

    .d-md-table-header-group {
        display: table-header-group !important
    }

    .col_right {
        right: -50px
    }

    .block {
        padding: 50px 50px 50px 0
    }
}

@media (min-width: 992px) {
    .line {
        bottom: 0;
        top: initial
    }

    .block__shape_2 {
        display: block
    }

    .sum_large {
        font-size: 105px;
        line-height: 113px
    }

    .cover-box {
        min-height: 120px
    }

    .box_info.cover_box {
        min-height: 120px
    }

    .rules-text {
        max-width: auto
    }

    .d-lg-table-header-group {
        display: table-header-group !important
    }

    .modal-window > div.modal_big {
        max-width: calc(100vw - 80px)
    }
}

@media (min-width: 1140px) {
    .box_info.cover_box {
        padding: 20px 0 20px 20px
    }

    .table-game {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 100vh
    }
}

@media (min-width: 1200px) {
    a[href^='tel:'] {
        white-space: nowrap;
        pointer-events: none;
        text-decoration: inherit;
        color: inherit
    }
}

@media (max-width: 575.98px) {
    .futured_timer {
        display: none;
    }

    table tr td {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    table tr td .cell__title {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.profile__top {
    display: flex;
    align-items: flex-start;
}

.profile__avatar-parent {
    position: relative;
}

.profile__avatar-wrapper {
    width: 46px;
    height: 46px;
    top: -112px;
    left: 0;
    position: absolute;
    border-radius: 50%;
    z-index: 2;
    outline: none;
}

.profile__avatar-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
}

.profile__avatar-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    right: -6px;
    bottom: -6px;
    background: url("./../img/camera.png") no-repeat center;
    background-size: 100% 100%;
    border-radius: 50%;
    cursor: pointer;
}

.profile__avatar-menu {
    width: 120px;
    height: 74px;
    position: absolute;
    right: -114px;
    bottom: -68px;
    box-sizing: border-box;

    background: #FFFFFF;
    border: 1px solid #42D0D2;
    box-shadow: 0px 0px 30px rgba(66, 208, 210, 0.1);

    font-family: "Blender Pro", sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    text-transform: uppercase;
    color: #353839;
    overflow: hidden;

    opacity: 0;
    visibility: hidden;
}

.profile__avatar-menu_active {
    opacity: 1;
    visibility: visible;
}

.profile__avatar-button {
    padding: 0 15px;
    transition: .3s;
}

.profile__avatar-button:hover {
    background: #42d0d2;
    color: #fff;
}

.profile__label {
    width: 100%;
    padding-bottom: 5px;
    padding-top: 10px;
    border-bottom: 1px solid #42D0D2;
    cursor: pointer;
}

.profile__avatar-delete {
    padding-top: 5px;
    padding-bottom: 10px;
    display: block;
    cursor: pointer;
}

/*# sourceMappingURL=style.css.map */

select option {
    color: black;
}
