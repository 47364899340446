@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap);

/* game-menu */
.game-menu {
    width: 100%;
    min-height: 120px;
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 0 30px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    background: url("./../img/game-menu-bg-new.png") no-repeat center;
    background-size: 100% 100%;
    filter: drop-shadow(0px 0px 40px rgba(66, 208, 210, 0.6));
}

.game-menu__arrow {
    display: none;
    cursor: pointer;
}

.game-menu__list {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.game-menu__elem {
    width: 25%;
    position: relative;
}

.game-menu__elem_mobile {
    display: none;
}

.game-menu__link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 1;
    cursor: pointer;
}

.game-menu__title {
    font-family: "Blender Pro", sans-serif;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
}

.game-menu__title_active {
    color: #42D0D2;
    text-decoration-line: underline
}

.game-menu__describe {
    max-width: 190px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #FFFFFF;
}

.game-menu__separator {
    content: "";
    width: 22px;
    height: 38px;
    background: url("./../img/game-menu-separator.png") no-repeat center;
}

@media screen and (max-width: 1150px) {
    .game-menu {
        min-height: 100px;
    }

    .game-menu__title {
        font-size: 14px;
    }

    .game-menu__describe {
        font-size: 10px;
    }
}

@media screen and (max-width: 800px) {
    .game-menu {
        padding: 0 10px;
    }
}

@media screen and (max-width: 715px) {
    .game-menu {
        height: 83px;
        min-height: 0;
        position: relative;
        flex-direction: column;
        justify-content: center;
        background: url("./../img/game-menu-bg-mobie.png") no-repeat center;
        background-size: 100% 100%;
        z-index: 4;
    }

    .game-menu__list {
        height: auto;
        position: absolute;
        top: 85px;
        display: none;
        flex-direction: column;
    }

    .game-menu__list_active {
        display: flex;
    }

    .game-menu__separator {
        display: none;
    }

    .game-menu__elem{
        width: 100%;
        padding: 16px 0;
        background: url("./../img/game-menu-mobile-elems.png") no-repeat center;
        background-size: 100% 100%;
    }

    .game-menu__elem_mobile {
        display: block;
        padding: 0;
        cursor: pointer;
        background: none;
    }

    .game-menu__elem_mobile-active {
        display: none;
    }

    .game-menu__describe {
        max-width: 200px;
        white-space: normal;
        font-size: 8px;
    }

    .game-menu__title {
        font-size: 18px;
    }

    .game-menu__arrow {
        width: 16px;
        height: 12px;
        position: absolute;
        top: 50%;
        margin-top: -6px;
        right: 50px;
        display: block;
        background: url("./../img/down-white.svg") no-repeat center;
        transition: .2s;
    }

    .game-menu__arrow_active {
        background: url("./../img/down-3.svg") no-repeat center;
        transform: rotateX(180deg);
    }
}

@media screen and (max-width: 500px) {
    .game-menu__arrow {
        right: 33px;
    }
}



/* Calendar */

.calendar {
    width: 100%;
    height: 100%;
    padding: 15px;
    border: 1px solid #42D0D2;
    background: #fff;
}

.calendar__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.calendar__select {
    position: relative;
}

.calendar__select-chosen {
    position: relative;
    font-family: "Blender Pro", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
}

.calendar__select-chosen_month {
    margin-right: 10px;
}

.calendar__select-chosen_year {
    margin-right: 94px;
}

.calendar__select-chosen::before,
.calendar__select-chosen::after {
    content: "";
    width: 5px;
    height: 1px;
    position: absolute;
    top: 50%;
    right: -10px;
    background: #000;
    opacity: .6;
    transform-origin: right center;
}

.calendar__select-chosen::before {
    transform: rotate(45deg);
}

.calendar__select-chosen::after {

    transform: rotate(135deg);
}

.calendar__select-chosen:hover::before,
.calendar__select-chosen:hover::after {
    opacity: 1;
}

.calendar__select-list {
    max-height: 105px;
    padding-bottom: 0;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 15px;
    margin: 0;
    position: absolute;
    left: 0;
    top: -4px;
    background: #fff;
    overflow: auto;
    transition: .2s;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.25);
    visibility: hidden;
    opacity: 0;
}

.calendar__select-list::-webkit-scrollbar { width: 3px; height: 3px;}
.calendar__select-list::-webkit-scrollbar-track {  background-color: #fff;}

.calendar__select-list_month {
    min-width: 77px;
}

.calendar__select-list_year {
    width: 58px;
}

.calendar__select-list_active {
    visibility: visible;
    opacity: 1;
}

.calendar__select-elem {
    font-family: "Blender Pro", sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
    transition: .2s;
}

.calendar__select-elem:hover {
    color: #42D0D2;
}

.calendar__week {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.calendar__week-box {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
}

.calendar__days {
    width: 100%;
}

.calendar__row {
    display: flex;
}

.calendar__day {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #DFDFDF;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}

.calendar__day_base {
    transition: .1s;
    cursor: pointer;
}

.calendar__day_base:hover {
    background: #42D0D2;
    color: #fff;
}

.calendar__day_current {
    background: #42D0D2;
    color: #fff;
}

.calendar__day_additional {
    background: #FCFCFC;
    color: #747474;
}



/* time list */
.time-list {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    border: 1px solid #42D0D2;
    background: #fff;
}

.time-list__box {
    width: 35%;
    height: 100%;
    padding: 0 5px;
    overflow: auto;
}

.time-list__box::-webkit-scrollbar { width: 2px; height: 3px;}
.time-list__box::-webkit-scrollbar-track {  background-color: #fff;}

.time-list__elem {
    padding: 2px 0;
    font-family: "Blender Pro", sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
    transition: .2s;
}

.time-list__elem:hover {
    color: #42D0D2;
}

/* game table */
.box_rating {
    max-height: 100%;
    border: none;
    border-top: 1px solid #42d0d2;
    position: relative;
}

.box_rating::before,
.box_rating::after {
    display: none;
}

.box__border-left {
    width: 1px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.box__border-left-top {
    width: 1px;
    height: calc(50% - 139px);
    position: absolute;
    top: 0;
    left: 0;
    background: #42d0d2;
}

.box__border-left-img {
    max-width: none;
    height: 139px;
    position: absolute;
    bottom: 50%;
    left: -10px;
}

.box__border-left-bottom {
    width: 1px;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #42d0d2;
}

.box__border-bottom {
    width: calc(100% - 18px);
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #42d0d2;
}

.box__border-right {
    width: 1px;
    height: calc(100% - 18px);
    position: absolute;
    top: 0;
    right: 0;
    background: #42d0d2;
}

.box__border-line-mini {
    width: 25px;
    height: 1px;
    position: absolute;
    bottom: 9px;
    right: -3px;
    background: #42d0d2;
    transform-origin: center;
    transform: rotate(-45deg);
}