@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap);

/* UI */

.ui-button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #fff;
    position: relative;
    outline: none;
}

.ui-button:focus {
    outline: none;
}

.ui-button:hover {
    color: #fff;
    opacity: .7;
}

.ui-button-text {
    position: relative;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
}

.ui-button-bg {
    position: absolute;
    filter: drop-shadow(0px 5px 14px rgba(66, 208, 210, 0.3));
}

/* quiz general */
.quiz {
    overflow: hidden;
    font-family: "Blender Pro", sans-serif;
}

.quiz__general-window {
    width: 590px;
    min-height: 425px;
    margin: 0 auto;
    margin-top: 123px;
    padding: 52px 60px 31px 60px;
    position: relative;
}

.quiz__dots {
    width: 143px;
    height: 136px;
    position: absolute;
    top: 162px;
    left: 98px;
    background: url("../img/quiz-dots.svg");
}

.quiz__dots_top {
    top: -33px;
    left: -60px;
}

.quiz__dots_bottom {
    right: -84px;
    bottom: -49px;
    left: auto;
    top: auto;
}

.quiz__window-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    filter: drop-shadow(0px 0px 80px rgba(255, 255, 255, 0.55));
    background: url("../img/quiz-window-mid.png") no-repeat center;
    background-size: 100% 100%;
}

.quiz__lines {
    width: 115px;
    height: 116px;
    position: absolute;
    right: -13px;
    bottom: -17px;
    background: url("../img/quiz-lines.svg") no-repeat center;
}

.quiz__general-content {
    position: relative;
}

.quiz__numbs {
    margin-bottom: 6px;
    font-family: "Roboto Condensed", sans-serif;
    color: #42D0D2;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
}

.quiz__numb-active {
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
}

.quiz__quest {
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 110%;
}

.quiz__list {
    margin-bottom: 30px;
    padding-left: 0;
}

.quiz__elem {
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
}

.quiz__label {
    min-width: 20px;
    height: 20px;
    margin-right: 20px;
    box-sizing: border-box;
    cursor: pointer;
    transition: .3s;
}

.quiz__label_normal {
    border: 1px solid #0F0F0F;
    opacity: .3;
}

.quiz__label_normal:hover {
    border: 1px solid #42D0D2;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(66, 208, 210, 0.6);
}

.quiz__label_active {
    background: #42D0D2;
    border: 1px solid #42D0D2;
}

.quiz__label_error {
    background: #D7263D url("../img/quiz-error.svg") no-repeat center;
}

.quiz__label_success {
    background: #42d0d2 url("../img/quiz-success.svg") no-repeat center;
}

.quiz__elem-text {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
}

.quiz__prompt {
    min-height: 78px;
    margin-bottom: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}

.quiz__prompt-text {
    width: 100%;
    padding: 15px 25px 15px 43px;
    position: relative;
}

.quiz__prompt_success {
    background: url("../img/quiz-prompt-success.png") no-repeat center;
    filter: drop-shadow(0px 0px 20px rgba(66, 208, 210, 0.3));
    background-size: 100% 100%;
}

.quiz__prompt_error {
    background: url("../img/quiz-prompt-error.png") no-repeat center;
    filter: drop-shadow(0px 0px 10px rgba(215, 38, 61, 0.4));
    background-size: 100% 100%;
}

.quiz__btn {
    width: 203px;
    height: 59px;
    margin: 0 auto;
}

/* quiz main */

.quiz__dots_add {
    display: none;
}

.quiz__window {
    width: calc(100vw - 155px);
    margin-top: 188px;
    margin-right: 0;
    margin-left: auto;
    position: relative;
    box-sizing: border-box;
}

.quiz__img-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    filter: drop-shadow(0px 0px 80px rgba(255, 255, 255, 0.55));
}

.quiz__img-bg_mob {
    display: none;
}

.quiz__img-main {
    position: absolute;
    top: 50%;
    margin-top: -350px;
    right: 0;
}

.quiz__img-main_mob {
    display: none;
}

.quiz__window-content {
    max-width: 480px;
    padding-top: 60px;
    padding-left: 80px;
    padding-bottom: 60px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.quiz__title {
    margin-bottom: 10px;
    font-size: 34px;
    line-height: 41px;
    text-transform: uppercase;
}

.quiz__describe {
    font-family: "Roboto", sans-serif;
    margin-bottom: 55px;
    font-size: 14px;
    line-height: 16px;
}

.quiz__button {
    width: 203px;
    height: 59px;
    margin-bottom: 8px;
}

.quiz__skip {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #000000;
    mix-blend-mode: normal;
    opacity: 0.5;
}

/* quiz result */
.quiz__result {
    max-width: 375px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.quiz__numbs_result {
    margin-bottom: 20px;
    font-size: 40px;
}

.quiz__numb-active_result {
    font-size: 90px;
}

.quiz__result-title {
    margin-bottom: 7px;
    font-size: 34px;
    line-height: 41px;
}

.quiz__result-subtitle {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
}

.quiz__share-block {
    max-width: 324px;
    width: 100%;
    height: 98px;
    margin-bottom: 40px;
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("../img/share-bg.png");
    background-size: 100% 100%;
    box-sizing: content-box;
}

.quiz__share-text {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 18px;
    line-height: 118.4%;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.quiz__share-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.quiz__share-link {
    margin: 0 10px;
    opacity: 1;
}

.quiz__share-link:hover {
    transform: translateY(-5px);
}

.quiz__share-icon {
    width: 33px;
    height: 33px;
}

.quiz__repeat-text {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
}

.quiz__repeat-link {
    margin-top: 10px;
    margin-bottom: 40px;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #000000;
}

.quiz__start-text {
    max-width: 352px;
    margin-bottom: 20px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #000000;
}

.quiz__start-link {
    width: 263px;
    height: 60px;
    opacity: 1;
}

.quiz__subscribe {
    max-width: 308px;
    margin-top: 12px;
    margin-bottom: 20px;
    font-family: "Roboto", sans-serif;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    opacity: 0.6;
}

.quiz__still {
    margin-top: 15px;
}
















@media screen and (max-width: 1200px) {
    .quiz__img-main_desk {
        display: none;
    }

    .quiz__img-main_mob {
        display: block;
        margin-top: -200px;
    }
}

@media screen and (max-width: 992px) {
    .quiz__general-window {
        max-width: 620px;
        width: 100%;
        min-height: 466px;
        margin-top: 254px;
        padding: 57px 89px 30px 89px;
    }

    .quiz__dots_start {
        top: 197px;
        left: 51px;
    }

    .quiz__dots_add {
        display: block;
        right: 56px;
        top: 670px;
        left: auto;
    }

    .quiz__img-main_desk {
        display: none;
    }

    .quiz__img-main_mob {
        display: block;
        margin-top: -125px;
        right: -32px;
    }

    .quiz__describe {
        max-width: 225px;
        margin-bottom: 40px;
    }

    .quiz__describe br {
        display: none;
    }

    .quiz__window {
        width: calc(100% - 64px);
        min-height: 500px;
        margin-right: auto;
        margin-top: 254px;
    }
    .quiz__img-bg_desk {
        display: none;
    }

    .quiz__img-bg_mob {
        display: block;

    }
}

@media screen and (max-width: 680px) {
    .quiz__img-main_mob {
        width: 405px;
        margin-top: -110px;
    }

    .quiz__general-window {
        width: calc(100% - 40px);
    }
}

@media screen and (max-width: 640px) {
    .quiz__general-window {
        margin-top: 72px;
        padding: 30px 20px;
    }

    .quiz__window-bg {
        background: #fff;
    }

    .quiz__prompt {
        background: none;
        filter: none;
    }

    .quiz__prompt-text {
        padding: 15px 6px 17px 20px;
    }

    .quiz__prompt_error {
        box-shadow: 0px 0px 20px rgba(215, 38, 61, 0.4);
        border: 1px solid #D7263D;
    }

    .quiz__prompt_success {
        box-shadow: 0px 0px 20px rgba(66, 208, 210, 0.3);
        border: 1px solid #42D0D2;
    }

    .quiz__window {
        min-height: 438px;
    }

    .quiz__img-main {
        width: 330px;
        margin-top: -62px;
    }

    .quiz__img-main {
        display: none;
    }

    .quiz__window {
        width: calc(100% - 40px);
        min-height: 0;
        margin-top: 72px;
        padding: 40px;
        padding-bottom: 50px;
        background: #fff;
    }

    .quiz__window-content {
        padding: 0;
    }

    .quiz__dots_start {
        top: -10px;
        left: -30px;
    }

    .quiz__dots_add {
        top: 366px;
        right: -27px;
    }

    .quiz__result {
        padding-top: 40px;
    }
}

@media screen and (max-width: 513px) {
    .quiz__dots_add {
        top: 402px;
        right: -27px;
    }
}

@media screen and (max-width: 449px) {
    .quiz__window {
        height: 505px;
    }

    .quiz__dots_add {
        top: 476px;
    }
}

@media screen and (max-width: 400px) {
    .quiz__window {
        padding: 40px 18px;
    }
}

@media screen and (max-width: 350px) {
    .quiz__start-link {
        width: 100%;
    }
}



.none {
    display: none;
}