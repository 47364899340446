@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap);

.header__avatar {
    min-width: 25px;
    min-height: 25px;
    border-radius: 50%;
    overflow: hidden;
}

.prediction__btn-cover {
    border-top:none;
    border-left: none;
    border-bottom: none;
}

.prediction__btn-cover::after {
    width: calc(100% - 64px);
    z-index: -1;
}

.prediction__btn-cover::before {
    content: "";
    width: calc(100% - 79px);
    position: absolute;
    bottom: -1px;
    right: 0px;
    border-bottom: 1px solid #42d0d2;
}

.prediction__btn-right {
    border-top: 1px solid #42d0d2;
    border-bottom: 1px solid #42d0d2;
}

.prediction__btn-left {
    height: 42px;
    position: relative;
    right: -1px;
    border-right: none;
    background: url("../img/instruction/forecast-left-active.png") no-repeat center;
    background-size: 100% 100%;
}

.prediction__btn-left.btn_deact {
    background: url("../img/instruction/forecast-left.png") no-repeat center;
    background-size: 100% 100%;
}

.prediction__btn-left.btn_disabled {
    background: url("../img/instruction/btn-opacity.png") no-repeat center;
    background-size: 100% 100%;
}

.prediction__btn-left:hover, .prediction__btn-left:focus {
    background: url("../img/instruction/forecast-left-active.png") no-repeat center;
    background-size: 100% 100%;
}

@media screen and (max-width: 1268px) {
    .game__button {
        margin-bottom: 15px;
        margin-left: 0;
    }

    .game__top {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}

.box_balance {
    padding: 35px;
    border: none;
    background: url("../img/balance-border.png") no-repeat center;
    background-size: 100% 100%;
}

.box_balance::after {
    content: none;
}


.blitz {
    position: relative;
    color: #fff;
    font-family: "Roboto", sans-serif;
    overflow: hidden;
}

.line-wrap {
    white-space: pre-line;
}

.blitz button:focus {
    outline: none;
}

.blitz__dots-right {
    width: 143px;
    height: 155px;
    position: absolute;
    background: url("./../img/quiz-dots.svg") no-repeat center;
    top: 0;
    right: -60px;
}

.blitz__dots-left {
    width: 143px;
    height: 155px;
    position: absolute;
    background: url("./../img/quiz-dots.svg") no-repeat center;
    top: 470px;
    left: -60px;
}

.blitz__container {
    max-width: 1255px;
    padding: 0 20px;
    margin: 0 auto;
    position: relative;
}

.blitz__select {
    margin-bottom: 30px;
    margin-top: 47px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #FFFFFF;
}

.blitz__option {
    width: 50%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Blender Pro", sans-serif;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: #42D0D2;
    border-radius: 0;
    transition: .3s;
}

.blitz__option:hover {
    opacity: .7;
}

.blitz__option_active {
    background: url("./../img/blitz-option-bg.png") no-repeat center;
    background-size: 100% 100%;
    color: #FFFFFF;
}

.blitz__option_active:hover {
    opacity: 1;
}

.blitz__top {
    margin-bottom: 52px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.blitz__top-left {
    max-width: 630px;
    width: 100%;
}

.blitz__title-main {
    margin-bottom: 3px;
    font-family: "Blender Pro", sans-serif;
    font-weight: normal;
    font-size: 64px;
    line-height: 77px;
}

.blitz__rules-top {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.blitz__rules-title {
    margin-right: 15px;
    font-family: "Blender Pro", sans-serif;
    font-weight: normal;
    font-size: 34px;
    line-height: 41px;
}

.blitz__rules-full {
    font-size: 12px;
    line-height: 14px;
    color: #42D0D2;
}

.blitz__rules-list {
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
}

.blitz__rules-elem {
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    background: url("./../img/blitz-elem-bg.png") no-repeat center;
    background-size: 100% 100%;
    font-family: "Roboto", sans-serif;
}

.blitz__rules-numb {
    margin-right: 20px;
    font-family: "Blender Pro", sans-serif;
    font-weight: normal;
    font-size: 34px;
    line-height: 41px;
}

.blitz__rules-text {
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
}

.blitz__condition {
    max-width: 594px;
    padding: 23px 30px 24px 30px;
    background: url("./../img/blitz-condition-bg.png") no-repeat center;
    background-size: 100% 100%;
    box-sizing: border-box;
}

.blitz__condition-title {
    font-family: "Blender Pro", sans-serif;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
}

.blitz__condition-text {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
}

.blitz__condition-warning {
    font-size: 16px;
    line-height: 19px;
}

.blitz__top-right {
    flex: 1;
    margin-left: 15px;
    margin-top: 77px;
}

.blitz__time-box {
    width: 495px;
    margin-right: 0;
    margin-left: auto;
}

.blitz__time-title {
    font-family: "Blender Pro", sans-serif;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
}

.blitz__time {
    width: 100%;
    margin-bottom: 52px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.blitz__time_small .blitz__time-numb {
    font-size: 90px;
    line-height: 140px;
}

.blitz__time-value {

}

.blitz__time-numb {
    margin-bottom: -20px;
    font-family: "Blender Pro", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 140px;
    line-height: 168px;
    -webkit-text-stroke: 1px #42D0D2;
    color: transparent;
}

.blitz__time-name {
    margin-left: 6px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
}

.blitz__time-dots {
    width: 12px;
    height: 100%;
    position: relative;
}

.blitz__time-dots::before,
.blitz__time-dots::after {
    width: 100%;
    height: 12px;
    content: "";
    background: #42D0D2;
    position: absolute;
    left: 0;
    bottom: 45px;
}

.blitz__time-dots::after {
    bottom: 80px;
}


.blitz__point-box {
    display: flex;
    align-items: center;
}

.blitz__point {
    padding: 15px 15px 9px 15px;
}

.blitz__point_victory {
    margin-right: 42px;
    background: url("./../img/blitz-victory-bg.png") no-repeat center;
    background-size: 100% 100%;
}

.blitz__point_game {
    background: url("./../img/blitz-game-bg.png") no-repeat center;
    background-size: 100% 100%;
}

.blitz__point-name {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
}

.blitz__point-value {
    font-family: "Blender Pro", sans-serif;
    font-weight: 900;
    font-size: 36px;
    line-height: 43px;
}

.blitz__blue {
    color: #42D0D2;
}

.blitz__point-small {
    font-size: 32px;
}

/* blitz card */

.blitz__card {
    margin-bottom: 30px;
    padding: 40px 30px 60px 30px;
    border: 1px solid #42D0D2;
}

.blitz__card-title {
    margin-bottom: 10px;
    font-family: "Blender Pro", sans-serif;
    font-weight: normal;
    font-size: 64px;
    line-height: 77px;
}

.blitz__card-info {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
}

.blitz__card-number,
.blitz__card-date{
    display: flex;
    align-items: center;
}

.blitz__card-number {
    margin-right: 20px;
}

.blitz__card-name {
    margin-right: 4px;
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    opacity: 0.4;
}

.blitz__bottom {
    display: flex;
    align-items: center;
}

.blitz__manager {
    display: flex;
    align-items: flex-start;
}

.blitz__manager-left {
    min-width: 285px;
    z-index: 2;
}

.blitz__manager-name {
    margin-bottom: 10px;
}

.blitz__card-button {
    width: 307px;
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: url("./../img/blitz-button.png") no-repeat center;
    background-size: 100% 100%;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    outline: none;
    transition: .3s;
}

.blitz__card-button::before {
    content: "";
    width: 37px;
    height: 37px;
    background: url("../img/blitz-small-ring.png") no-repeat center;
    position: absolute;
    left: 0;
    bottom: 9px;
    z-index: -1;
}

.blitz__card-button::after {
    content: "";
    width: 61px;
    height: 61px;
    background: url("../img/blitz-big-ring.png") no-repeat center;
    position: absolute;
    right: 0;
    top: -5px;
    z-index: -1;
}

.blitz__card-button::before,
.blitz__card-button::after {
    animation: rotateRing 13s infinite linear;
}

@keyframes rotateRing {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.blitz__card-button:focus {
    outline: none;
}

.blitz__card-wrapper-prompt {
    position: relative;
}

.blitz__card-prompt {
    max-width: 283px;
    padding: 15px 12px;
    position: absolute;
    right: -50%;
    bottom: 50%;
    background: url("./../img/blitz-prompt-bg.png") no-repeat center;
    background-size: 100% 100%;
    text-align: left;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}

.blitz__card-button:hover {
    opacity: .7;
}

.blitz__manager-right {
    min-width: 290px;
    margin: 0 50px;
}


/* blitz select */
.blitz-select {
    width: 100%;
    height: 36px;
    position: relative;
    background: #000;
    outline: none;
}

.blitz-select__top {
    max-height: 36px;
    height: 36px;
    padding-left: 10px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid #42D0D2;
    background: #000;
    cursor: pointer;

    font-family: 'Blender Pro', sans-serif;
    font-weight: 700;
    font-size: 16px;
}

.blitz-select__arrow {
    width: 10px;
    height: 6px;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -3px;
    background: url("./../img/instruction/forecast-direction-active.svg") no-repeat center;
    background-size: 100% 100%;
}

.blitz-select__bottom {
    width: 100%;
    max-height: 96px;
    position: absolute;
    top: 100%;
    left: 0;
    background: #000;
    border-right: 1px solid #42D0D2;
    border-left: 1px solid #42D0D2;
    overflow: auto;
    opacity: 0;
    visibility: hidden;
}

.blitz-select__bottom_active {
    opacity: 1;
    visibility: visible;
}

.blitz-select__list {
    padding: 0;
    margin: 0;
}

.blitz-select__elem {
    margin: 0;
    padding: 3px 10px;
    border-bottom: 1px solid #42D0D2;
    border-top: 1px solid #42D0D2;
    font-size: 16px;
    cursor: pointer;

    transition: .3s;
}

.blitz-select__elem:hover {
    background: #42D0D2;
    color: #fff;
}

/* blitz forecast */
.blitz-forecast {
    width: 100%;
    min-height: 281px;
    margin-bottom: 30px;
    position: relative;
}

.blitz-forecast__img {
    width: 1064px;
    max-width: none;
    margin: 0 auto;
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -532px;
    top: 0;
}

.blitz-forecast__window {
    padding: 36px 52px 33px 31px;
    border: 1px solid #42D0D2;
}

.blitz-forecast__title {
    margin-bottom: 4px;
    font-family: "Blender Pro", sans-serif;
    font-weight: normal;
    font-size: 34px;
    line-height: 41px;
    text-transform: uppercase;
}

.blitz-forecast__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blitz-forecast__progress {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
}

.blitz-forecast__progress::before {
    content: "";
    width: calc(100% - 15px);
    height: 3px;
    background: url("./../img/blitz-line.png") no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    left: -10px;
    top: 44px;
}

.blitz-forecast__value_asset {
    margin-top: -10px;
    display: flex;
}

.blitz-forecast__item {
    width: 138px;
}

.blitz-forecast__item_asset {
    width: 140px;
    margin-right: 20px;
}

.blitz-forecast__name {
    min-height: 34px;
    margin-bottom: 34px;
    font-family: "Blender Pro", sans-serif;
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
}

.blitz-forecast__price {
    margin-bottom: 3px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}

.blitz-forecast__different {
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 12px;

}

.blitz-forecast__different_negative {
    color: #d7263d;
}

.blitz-forecast__different_positive {
    color: #51CB20;
}

.blitz-forecast__different::before {
    content: "";
    width: 5px;
    height: 13px;
}

.blitz-forecast__different_negative::before {
    background: url("./../img/instruction/arrow-red.svg") no-repeat center;
    background-size: 100% 100%;
}

.blitz-forecast__different_positive::before {
    background: url("./../img/instruction/arrow-green.svg") no-repeat center;
    background-size: 100% 100%;
}

.blitz-forecast__time {
    display: flex;
    align-items: center;
}

.blitz-forecast__time-left {
    margin-right: 20px;
    font-family: "Blender Pro", sans-serif;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
}

.blitz-forecast__blue {
    font-size: 36px;
    line-height: 43px;
    color: #42D0D2;
}

.blitz-forecast__time-right {
    display: flex;
    align-items: flex-start;
}

.blitz-forecast__minute {
    margin: 0 16px;
}

.blitz-forecast__time-value {
    font-family: "Blender Pro", sans-serif;
    font-weight: bold;
    font-size: 44px;
    line-height: 32px;
    -webkit-text-stroke: 1px #42D0D2;
    color: transparent;
}

.blitz-forecast__time-name {
    font-size: 16px;
    line-height: 19px
}

.blitz-forecast__result {
    width: 382px;
    height: 153px;
    padding: 23px 40px 31px 40px;
    position: relative;
    top: -55px;
    background: url("./../img/blitz-victory.png") no-repeat center;
    background-size: 100% 100%;
    box-sizing: border-box;
}

.blitz-forecast__result-name {
    margin-bottom: 20px;
    font-family: "Blender Pro", sans-serif;
    font-weight: normal;
    font-size: 34px;
    line-height: 110%;
    color: #000;
}

.blitz-forecast__result-button {
    width: 249px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("./../img/btn-simple.png") no-repeat center;
    background-size: 100% 100%;
    color: #fff;
    font-family: "Blender Pro", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
}

.blitz-forecast__result-button:focus {
    outline: none;
}

/* blitz result */
.blitz-result {

}

.blitz-result__title {
    margin-bottom: 20px;
    font-family: "Blender Pro", sans-serif;
    font-weight: normal;
    font-size: 34px;
    line-height: 41px;
    text-transform: uppercase;
}

.blitz-result__window {

}

.blitz-result__window_empty {
    width: 100%;
    height: 299px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #42D0D2;
    box-sizing: border-box;
}

.blitz-result__description {
    max-width: 300px;
    font-size: 14px;
    line-height: 16px;
    opacity: 0.3;
    text-align: center;
}


.blitz-result__top {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blitz-result__subtitle {
    width: 25%;
    font-family: "Blender Pro", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    box-sizing: border-box;
}

.blitz-result__subtitle_number {
    padding-left: 36px;
}

.blitz-result__subtitle_result {
    text-align: center;
}

.blitz-result__subtitle_point {
    text-align: right;
    padding-right: 40px;
}

.blitz-result__subtitle_type {
    text-align: right;
}

.blitz-result__list {
    max-height: 620px;
    margin: 0;
    padding: 0;
    overflow: auto;
}

.blitz-result__elem {
    width: 100%;
    height: 62px;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

.blitz-result__elem_success {
    background: radial-gradient(50% 50% at 50% 50%, rgba(66, 208, 210, 0) 24.48%, rgba(66, 208, 210, 0.24) 100%);
}

.blitz-result__elem_error {
    background: radial-gradient(50% 50% at 50% 50%, rgba(215, 38, 61, 0) 24.48%, rgba(215, 38, 61, 0.24) 100%);
}

.blitz-result__cell {
    width: 25%;
    box-sizing: border-box;
}

.blitz-result__cell_type {
    text-align: right;
    font-family: "Roboto Condensed", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}

.blitz-result__subcell-type {
    width: 70px;
    display: inline-block;
    text-align: center;
}

.blitz-result__left {
    padding-left: 38px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.blitz-result__value {
    text-align: center;
    font-family: "Blender Pro", sans-serif;
    font-style: italic;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
}

.blitz-result__value_success {
    color: #42D0D2;
}

.blitz-result__value_error {
    color: #D7263D;
}

.blitz-result__number {
    margin-bottom: 1px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}

.blitz-result__date {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}

.blitz-result__point {
    padding-right: 38px;
    text-align: right;

    font-family: "Blender Pro", sans-serif;
    font-style: italic;
    font-weight: 100;
    font-size: 14px;
    line-height: 22px;
}


.blitz-result__point-value {
    font-weight: bold;
    font-size: 18px;
}

.blitz-result__point-value_success {
    color: #42D0D2;
}

.blitz-result__point-value_error {
    color: #D7263D;
}

.blitz-forecast__close {
    top: 11px;
    right: 14px;
}

/* blitz modal */
.blitz-modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0);
    visibility: hidden;
    transition: .5s;
    z-index: 150;
}

.blitz-modal_active {
    background: rgba(0, 0, 0, .7);
    visibility: visible;
}

.blitz-modal__window {
    max-width: 341px;
    padding: 20px;
    position: relative;
    box-sizing: border-box;
    background: #fff;
    transform: translateY(-1000px);
    transition: .5s;
}

.blitz-modal__window_active {
    transform: translateY(0);
}

.blitz-modal__close {
    top: 10px;
    right: 15px;
    z-index: 2;
}

.blitz-modal__title {
    margin-bottom: 20px;
    padding-bottom: 10px;
    position: relative;
    font-family: "Blender Pro", sans-serif;
    font-weight: normal;
    font-size: 34px;
    line-height: 110%;
    text-transform: uppercase;
    color: #000;
}

.blitz-modal__title::after {
    content: "";
    width: 130px;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: url("./../img/blitz-modal-line.png") no-repeat center;
    background-size: 100% 100%;
}

.blitz-modal__description {
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 148.4%;
    color: #000;
}

.blitz-modal__button {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #42D0D2;

    font-family: "Blender Pro", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-transform: uppercase;
    transition: .3s;
}

.blitz-modal__button-wrapper {
    position: relative;
}

.blitz-modal__button_disabled {
    background: #ccc;
}

.blitz-modal__button:hover {
    opacity: .7;
}

.blitz-modal__button_disabled:hover {
    opacity: 1;
}

.blitz-modal__button:focus {
    outline: none;
}

.blitz-modal__subtitle {
    font-family: "Blender Pro", sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 110%;
    text-transform: uppercase;
}

.blitz-modal__time {
    display: flex;
    align-items: flex-start;
}

.blitz-modal__value {
    font-family: "Blender Pro", sans-serif;
    font-weight: bold;
    font-size: 52px;
    line-height: 42px;
    -webkit-text-stroke: 1px #42D0D2;
    color: transparent;
}

.blitz-modal__name {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: normal;
    font-size: 8px;
    line-height: 9px;
    text-transform: uppercase;
}

.blitz-modal__time-dots {
    margin: 0 4px;
    font-family: "Blender Pro", sans-serif;
    font-weight: bold;
    font-size: 52px;
    line-height: 41px;
    -webkit-text-stroke: 1px #000;
    color: transparent;
}

@media screen and (max-width: 1215px) and (min-width: 1115px) {
    .blitz__card-prompt {
        max-width: 150px;
        right: -16%;
    }
}

@media screen and (max-width: 1177px) {
    .blitz-forecast__bottom {
        flex-direction: column;
        align-items: flex-start;
    }

    .blitz-forecast__progress {
        margin-bottom: 24px;
    }

    .blitz-forecast__result {
        top: 0;
    }
}

@media screen and (max-width: 1114px) {
    .blitz__container {
        padding: 0 74px;
    }

    .blitz__dots-right {
        display: none;
    }

    .blitz__top {
        margin-bottom: 23px;
        flex-direction: column;
    }

    .blitz__top-left {
        max-width: none;
    }

    .blitz__top-right {
        width: 100%;
        margin-top: 44px;
        margin-left: 0;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .blitz__point-box {
        flex-direction: column;
        align-items: flex-start;
    }

    .blitz__point_victory {
        margin-right: 0;
        margin-bottom: 15px;
    }

    .blitz__time {
        max-width: 319px;
        margin-bottom: 0;
    }

    .blitz__time_small {
        max-width: 290px;
    }

    .blitz__time-numb {
        font-size: 90px;
        line-height: 108px;
    }

    .blitz__time-box {
        width: 319px;
    }

    .blitz__time-title {
        max-width: 200px;
    }

    .blitz__time-dots {
        width: 8px;
    }

    .blitz__time-dots::before,
    .blitz__time-dots::after {
        height: 8px;
    }

    .blitz__time-dots::before {
        bottom: 33px;
    }

    .blitz__time-dots::after {
        bottom: 58px;
    }

    .blitz__time-numb {
        margin-bottom: -10px;
    }

    .blitz__time_small .blitz__time-numb {
        font-size: 50px;
        line-height: 90px;
    }

    .blitz__time_small .blitz__time-dots::before,
    .blitz__time_small .blitz__time-dots::after {
        width: 6px;
        height: 6px;
    }

    .blitz__time_small .blitz__time-dots::before {
        bottom: 38px;
    }

    .blitz__time_small .blitz__time-dots::after {
        bottom: 55px;
    }

    .blitz__time-name {
        margin-left: 5px;
        font-size: 13px;
        line-height: 15px;
    }

    .blitz__point_game {
        width: 229px;
    }

    .blitz__bottom {
        flex-direction: column;
        align-items: flex-start;
    }

    .blitz__manager {
        margin-bottom: 27px;
    }

    .blitz__card {
        padding: 20px;
    }

    .blitz__card-title {
        margin-bottom: 10px;
        font-size: 42px;
        line-height: 44px;
    }

    .blitz__card-info {
        margin-bottom: 27px;
    }

    .blitz__manager-right {
        margin-right: 0;
        margin-left: 20px;
    }
}

@media screen and (max-width: 900px) {
    .blitz-forecast__window {
        padding: 20px;
    }

    .blitz-forecast__progress {
        width: 100%;
    }

    .blitz-forecast__progress::before {
        width: 100%;
        left: 0;
    }

    .blitz-forecast__item {
        width: auto;
    }

    .blitz-forecast__result {
        width: 100%;
        height: 83px;
        display: flex;
        align-items: center;
    }

    .blitz-forecast__result-name {
        margin-bottom: 0;
        margin-right: 30px;
    }

    .blitz-forecast__result-name {
        font-size: 24px;
    }
}

@media screen and (max-width: 750px) {
    .blitz-result__subtitle {
        width: 20%;
    }

    .blitz-result__subtitle_result {
        width: 40%;
    }

    .blitz-result__subtitle_number {
        width: 20%;
        padding-left: 10px;
    }

    .blitz-result__subtitle_point {
        width: 20%;
        padding-right: 10px;
    }

    .blitz-result__left {
        width: 20%;
        padding-left: 10px;
    }

    .blitz-result__point {
        width: 20%;
        padding-right: 10px;
    }

    .blitz-result__value {
        width: 40%;
        font-size: 20px;
    }

    .blitz-result__cell_type {
        width: 20%;
    }
}

@media screen and (max-width: 740px) {
    .blitz__manager {
        flex-direction: column;
    }

    .blitz__manager-left {
        margin-bottom: 18px;
    }

    .blitz__manager-right {
        margin-left: 0;
    }
}

@media screen and (max-width: 704px) {
    .blitz__title-main {
        margin-bottom: 0px;
        font-size: 44px;
        line-height: 53px;
    }

    .blitz__rules-title {
        font-size: 24px;
        line-height: 29px;
    }

    .blitz__rules-top {
        flex-direction: column;
        align-items: flex-start;
    }

    .blitz__rules-list {
        margin-bottom: 19px;
        font-family: "Roboto", sans-serif;
    }

    .blitz__condition {
        padding: 18px 5px 25px 24px;
    }

    .blitz__top-right {
        margin-top: 10px;
        margin-left: 0;
    }

    .blitz__top-right {
        flex-direction: column-reverse;
    }

    .blitz__time-box {
        margin-left: 0;
    }

    .blitz__point-box {
        margin-bottom: 35px;
    }

    .blitz__point {
        max-width: 321px;
        width: 100%;
    }

    .blitz__time-title {
        max-width: none;
    }

    .blitz-forecast__progress {
        margin-bottom: 5px;
        flex-direction: column;
    }

    .blitz-forecast__progress::before {
        display: none;
    }

    .blitz-forecast__item {
        width: 100%;
        margin-bottom: 24px;
    }

    .blitz-forecast__name {
        width: 100%;
        min-height: auto;
        margin-bottom: 11px;
        padding-bottom: 14px;
        border-bottom: 1px solid #42D0D2;
    }

    .blitz-forecast__name br {
        display: none;
    }

    .blitz-forecast__value_asset {
        margin-top: 0;
    }
}

@media screen and (max-width: 685px) {
    .blitz-forecast__result {
        height: 133px;
        padding: 20px;
        padding-top: 10px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .blitz-forecast__result-name {
        margin-right: 0;
        margin-left: 15px;
    }
}

@media screen and (max-width: 640px) {
    .blitz__card-prompt {
        max-width: 220px;
        right: 0;
    }
}

@media screen and (max-width: 555px) {
    .blitz-forecast__time {
        flex-direction: column;
        align-items: flex-start;
    }

    .blitz-forecast__time-left {
        margin-right: 0;
        margin-bottom: 13px;
    }
}

@media screen and (max-width: 500px) {
    .blitz__container {
        padding: 0 20px;
    }
}

@media screen and (max-width: 435px) {
    .blitz-forecast {
        min-height: 230px;
    }

    .blitz-forecast__img {
        width: 880px;
        margin-left: -440px;
    }
}

@media screen and (max-width: 390px) {
    .blitz__card-wrapper-prompt {
        width: 100%;
    }

    .blitz__card-button {
        width: 100%;
    }

    .blitz__card-prompt {
        max-width: 126px;
        right: -12px;
    }

    .blitz__manager {
        width: 100%;
    }

    .blitz__manager-left {
        min-width: auto;
        width: 100%;
    }

    .blitz__manager-right {
        width: 100%;
        min-width: auto;
    }
}

@media screen and (max-width: 360px) {
    .blitz__time-box {
        width: 100%;
    }

    .blitz__time-numb {
        font-size: 75px;
    }

    .blitz__time-dots::before {
        bottom: 36px;
    }

    .blitz__time-dots::after {
        bottom: 60px;
    }

    .blitz__time-title {
        font-size: 21px;
    }
}

@media screen and (max-width: 350px) {
    .blitz__card-info {
        flex-direction: column;
        align-items: flex-start;
    }

    .blitz__card-number {
        margin-bottom: 5px;
    }

    .blitz-result__window {
        zoom: 0.85;
    }
}