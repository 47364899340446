.robot {
    pointer-events: none;
}

.robot_bottom {
    position: absolute;
    bottom: -21px;
}

div, input {
    box-sizing: border-box;
}


.btn:active, .btn:focus {
    box-shadow: none;
}


.clickable {
    cursor: pointer;
    transform: translate(0, 0);
}

.clickable:active {
     transform: translate(1px, 1px);
}

button:disabled {
    pointer-events: none;
}


.cover_btn {
    cursor: pointer;
}



.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical
}

.table_card-fixed {
    table-layout: fixed;
}


@media(max-width: 710px) {
    .faq-page .robot {
        display: none !important;
    }

    .user_prediction {
        display: none;
    }

    .fixed-container__mobile {
        max-width: 100vw;
        overflow: hidden;
    }
}


.apexcharts-canvas text {
    fill: white;
}

.apexcharts-menu,
.apexcharts-tooltip {
    color: white !important;
}

.left {
    z-index: -1;
}

.right {
    z-index: -1;
}

.notifications {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 12px;
}

.notifications .modal-close {
    width: 60px;
    height: 60px;
    right: 0;
    top: 0;
}


.notifications .line__100 {
    width: 100%;
    height: 2px;
    display: block;
    background-color: #42d0d2;
    position: static;
    left: 0;
    margin: 10px 0;
}

.notifications__button {
    opacity: .8;
    transition: all ease 150ms;
    cursor: pointer;
    position: relative;
}

.notifications__button.active-message::after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #42D0D2;
    position: absolute;
    border-radius: 50%;
    right: -2px;
    top: 1px;
}

.notifications__button:hover {
    opacity: 1;
}

.notification-wrapper {
    position: absolute;
    min-width: 341px;
    max-height: 234px;
    background: #fff;
    padding: 40px 20px 20px;
    z-index: 3;
    color: #000;
    top: 38px;
    overflow-y: auto;
}

@media (max-width: 400px) {
    .notification-wrapper {
        min-width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
        position: fixed;
        left: 0;
    }
}

.box-referal-main {
    border-top-right-radius: 48px 48px;
    border:1px solid #42d0d2;
    padding: 20px;
}

.box-referal-qr {
    border-bottom-right-radius: 25px 25px;
    width: 80px;
    height: 80px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #42d0d2;
}

.box-referal-main .fw-bold {
    font-weight: 600;
}

.box-referal-main .like-input {
    border: 1px solid rgba(66, 208, 210, 0.7);
    padding: 8px 10px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.box-referal-main .like-input p {
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.box-referal-main .like-input:hover {
    border: 1px solid rgba(66, 208, 210, 1);
    transition: all 0.3s ease-in-out;

}

.box-referal-main .like-input p {
    font-size: 12px;
}

.game__top {
    display: flex;
    align-items: center;
}

.game__button {
    min-width: 140px;
    height: 40px;
    margin-left: 45px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("./../img/btn-simple.png") no-repeat center;
    background-size: 100% 100%;
    color: #fff;
    font-family: 'Blender Pro';
    font-size: 16px;
    font-weight: 700;
    padding: 0 30px;
    line-height: 40px;
    text-align: center;
}

.game__button:focus {
    outline: none;
}
