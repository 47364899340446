@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap);

.share {
    width: 100%;
    height: 100vh;
    padding-top: 56px;
    position: fixed;
    left: 0;
    top: 0;
    background: url("./../img/share-page/bg.png") no-repeat center;
    background-size: cover;
    box-sizing: border-box;
    z-index: 200;

    font-family: "Blender Pro", sans-serif;
    font-weight: normal;
    color: #000;
    background-color: #000;
    overflow-x: hidden;
    overflow-y: auto;
}


.share__container {
    max-width: 1200px;
    padding: 0 94px;
    position: relative;
}

.share__link {
    margin-bottom: 49px;
    display: block;
}

.share__block {
    position: relative;
    display: inline-block;
}

.share__dots {
    width: 143px;
    height: 137px;
    position: absolute;
    left: -31px;
    top: -16px;
}

.share__lines {
    width: 115px;
    height: 116px;
    position: absolute;
    right: -12px;
    bottom: -12px;
}

.share__window {
    max-width: 426px;
    width: 426px;
    padding: 33px 20px 50px 71px;
    background: url("./../img/share-page/window.png") no-repeat center;
    background-size: 100% 100%;
    filter: drop-shadow(0px 0px 80px rgba(255, 255, 255, 0.55));
    box-sizing: border-box;
}

.share__title {
    margin-bottom: 2px;
    font-size: 34px;
    line-height: 41px;
}

.share__title-blue {
    color: #42D0D2;
}

.share__title-bold {
    font-size: 64px;
    line-height: 77px;
    font-weight: 600;
}

.share__subtitle {
    max-width: 274px;
    margin-bottom: 22px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 16px;
}

.share__text {
    max-width: 294px;
    margin-top: 27px;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
}

.share__window-link {
    width: 203px;
    height: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("./../img/share-page/button.png") no-repeat center;
    background-size: 100% 100%;
    filter: drop-shadow(0px 5px 14px rgba(66, 208, 210, 0.3));

    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;

    color: #fff;
}

.share__window-link:hover {
    color: #fff;
}

.share__window-link::before {
    content: "";
    width: 110px;
    height: 18px;
    position: absolute;
    left: 0;
    top: -11px;
    background: url("./../img/share-page/button-top.png") no-repeat center;
    background-size: 100% 100%;
}

.share__window-link::after {
    content: "";
    width: 55px;
    height: 10px;
    position: absolute;
    right: 7px;
    bottom: -8px;
    background: url("./../img/share-page/button-bottom.png") no-repeat center;
    background-size: 100% 100%;
}

.share__img {
    max-height: 800px;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    object-fit: cover;
}

@media screen and (max-width: 800px) {
    .share__img {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .share__container {
        padding: 0 20px;
    }

    .share__block {
        display: block;
    }

    .share__window {
        max-width: none;
        width: 100%;
    }
}

@media screen and (max-width: 450px) {
    .share__window {
        padding: 30px;
        background: #fff;
    }
}

@media screen and (max-width: 410px) {
    .share__window {
        padding: 30px 20px;
    }

    .share__title {
        font-size: 28px;
    }

    .share__title-bold {
        font-size: 42px;
    }
}