@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap);

/* instruction */
.instruction {
    width: 100vw;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    background: rgba(0, 0, 0, .85);
    color: #fff;
    font-family: "Roboto", sans-serif;
}

.instruction__manager {
    display: none;
}

.instruction__container {
    display: flex;
    align-items: flex-start;
}

.instruction__window {
    max-width: 1251px;
    width: 100%;
    background: url("./../img/instruction-bg.png");
    background-size: 100% 100%;
    filter: drop-shadow(0px 0px 80px rgba(66, 208, 210, 0.4));
}

.instruction__window_1 {
    width: calc(100vw - 49px);
    padding: 70px 91px 68px 57px;
    margin-top: 68px;
    box-sizing: border-box;
}

.instruction__window_2 {
    max-width: 511px;
    width: 100%;
    padding: 70px 91px 68px 57px;
    margin-top: 68px;
    display: inline-block;
    box-sizing: border-box;
    background: url("../img/instruction/step2-bg.png") no-repeat center;
    background-size: 100% 100%;
}

.instruction__window_2 .instruction__content-right {
    display: none;
}

.instruction__window_2 .instruction__table {
    width: 307px;
}

.instruction__window_2 .instruction__title {
    width: 115%;
}

.instruction__window_3 {
    width: calc(100vw - 49px);
    padding: 70px 91px 68px 57px;
    margin-top: 68px;
    box-sizing: border-box;
}

.instruction__window_4 {
    max-width: none;
    background: none;
}

.instruction__window_5 {
    max-width: none;
    width: calc(100% - 49px);
    padding-top: 81px;
    padding-bottom: 208px;
    padding-left: 83px;
    margin-top: 68px;
    position: relative;
}

.instruction__describe {
    max-width: 534px;
    width: 100%;
    min-height: 241px;
    padding: 28px 50px;
    margin-top: 68px;
    margin-left: 108px;
    display: inline-block;
    border: 1px solid #42D0D2;
    box-shadow: 0px 0px 80px rgba(66, 208, 210, 0.4);
    box-sizing: border-box;
    font-family: "Roboto Condensed", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    background: #000;
}

.instruction__describe-button {
    display: none;
}

.instruction__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.instruction__content-left {
    max-width: 627px;
}

.instruction__title {
    margin-bottom: 30px;
    font-family: "Blender Pro", sans-serif;
    font-size: 64px;
    line-height: 77px;
}

.instruction__subtitle {
    margin-bottom: 18px;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
}

.instruction__row {
    padding-bottom: 11px;
    border-bottom: 1px solid #979797;
}

.instruction__row_head {
    border: none;
}

.instruction__cell {
    padding: 11px 0;
}

.instruction__cell_head {
    font-family: "Blender Pro", sans-serif;
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
}

.instruction__cell_asset {
    max-width: 123px;
    margin-right: 18px;
    display: flex;
    align-items: center;
}

.instruction__name {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.instruction__name-short {
    margin-bottom: 6px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #42D0D2;
}

.instruction__name-full {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 10px;
    line-height: 12px;
}

.instruction__img-wrapper {
    min-width: 45px;
    height: 48px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../img/instruction/bg.svg") no-repeat center;
}

.instruction__logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
}

.instruction__prices {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.instruction__price-top {
    margin-bottom: 3px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}

.instruction__price-bottom {
    display: flex;
    align-items: center;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 10px;
    line-height: 12px;
}

.instruction__price-bottom::before {
    content: "";
    min-width: 6px;
    height: 13px;
    margin-right: 3px;
    display: block;
}

.instruction__price-bottom_green {
    color: #51CB20;
}

.instruction__price-bottom_green::before {
    background: url("../img/instruction/arrow-green.svg") no-repeat center;
}

.instruction__price-bottom_red {
    color: #D7263D;
}

.instruction__price-bottom_red::before {
    background: url("../img/instruction/arrow-red.svg") no-repeat center;
}

.instruction__cell_forecast {
    margin-right: 42px;
    margin-left: 28px;
    display: flex;
}

.instruction__button {
    width: 307px;
    height: 90px;
    margin-top: 30px;
    background: url("../img/instruction/button.png") no-repeat center;
    background-size: 100% 100%;
    color: #fff;
}

/* instruction right */
.instruction__content-right {
    max-width: 450px;
    padding-top: 150px;
}

.instruction__right-title {
    margin-bottom: 25px;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 18px;
    line-height: 21px;
}

.instruction__right-row {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #42D0D2;
}

.instruction__right-row_last {
    border: none;
}

.instruction__right-name {
    width: 123px;
    margin-right: 10px;
}

.instruction__right-text {
    max-width: 327px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
}

.instruction__right-name_logo {
    display: flex;
}

.instruction__right-name_price {
    font-family: "Blender Pro", sans-serif;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
}

.instruction__right-name_arrow {
    display: flex;
    height: 37px;
}

/* forecast button */
.forecast-button {
    height: 37px;
    display: flex;
    align-items: center;
}

.forecast-button__direction-main {
    width: 35px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;
    cursor: pointer;
    box-sizing: border-box;
}

.forecast-button__left {
    position: relative;
    right: -1px;
    background: url("../img/instruction/forecast-left.svg") no-repeat center;
    border-radius: 0;
}

.forecast-button__left_active,
.forecast-button__left:hover {
    background: url("../img/instruction/forecast-left-active.svg") no-repeat center;
}

.forecast-button__left_active .forecast-button__up,
.forecast-button__left:hover .forecast-button__up {
    background: url("../img/instruction/forecast-arrow-active.svg") no-repeat center;
}

.forecast-button__up {
    width: 8px;
    height: 18px;
    background: url("../img/instruction/forecast-arrow.svg") no-repeat center;
}

.forecast-button__right {
    background: url("../img/instruction/forecast-right.svg") no-repeat center;
    border-radius: 0;
}

.forecast-button__down {
    width: 8px;
    height: 18px;
    background: url("../img/instruction/forecast-arrow.svg") no-repeat center;
    transform: rotate(180deg);
}

.forecast-button__right_active,
.forecast-button__right:hover {
    background: #42D0D2;
    outline: none;
}

.forecast-button__right_active .forecast-button__down,
.forecast-button__right:hover .forecast-button__down {
    background: url("../img/instruction/forecast-arrow-active.svg") no-repeat center;
    outline: none;
}


.forecast-button__info {
    width: 98px;
    height: 97%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url("../img/instruction/forecast-info.png") no-repeat center;
    background-size: 100% 100%;
    box-sizing: border-box;
}

.forecast-button__managers {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.forecast-button__manager {
    width: 10px;
    height: 6px;
    padding: 6px;
    display: block;
    background: url("../img/instruction/forecast-direction.svg") no-repeat center;
    cursor: pointer;
}

.forecast-button__manager-bottom {
    transform: rotateX(180deg);
}

.forecast-button__manager:hover {
    background: url("../img/instruction/forecast-direction-active.svg") no-repeat center;
    transform: rotateX(180deg);
    outline: none;
}

.forecast-button__manager_active {
    background: url("../img/instruction/forecast-direction-active.svg") no-repeat center;
    transform: rotateY(180deg);
}

.forecast-button__manager-top.forecast-button__manager_active {
    transform: rotateX(180deg);
}

.forecast-button__manager_active, .forecast-button__manager-bottom:hover {
    transform: rotate(0deg);
    outline: none;
}



.forecast-button__text {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}

/* step */

.step {
    margin-top: 74px;
    width: 20%;
    position: relative;
}

.step__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    filter: drop-shadow(5px 0px 30px rgba(66, 208, 210, 0.7));
}

.step__block {

}

.step__block::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url("./../img/step-bg.png") no-repeat center;
    background-size: 100% 100%;
    z-index: 2;
}

.step__box {
    position: relative;
    border: none;
    color: #fff;
    z-index: 3;
    font-size: 12px ;
}

.step__manager {
    max-width: 242px;
    margin-left: auto;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.step__direction {
    width: 91px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    outline: none;
}

.step__direction:focus {
    outline: none;
}

.step__direction::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url("./../img/direction-black.png") no-repeat center;
    transition: .3s;
}

.step__direction_next::before {
    transform: rotateY(180deg);
}

.step__direction:hover::before {
    background: url("./../img/direction-white.png") no-repeat center;
}

.step__arrow {
    position: relative;
}

.step__arrow_prev {
    margin-right: 5px;
}

.step__arrow_next {
    margin-left: 5px;
    transform: rotateY(180deg);
}

.step__manager-text {
    position: relative;
    font-family: "Blender Pro", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    color: #42D0D2;
}

.step__numbers {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #fff;
}

.step__number-active {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
}

.step__first {
    width: calc(100% - 49px);
    position: absolute;
    top: 407px;
    left: 0;
}


/* prediction */
.prediction {
    margin-top: 223px;
}

.prediction__result {
    width: 316px;
    padding: 36px 28px 40px 40px;
    margin: 0 auto;
    position: relative;
    right: -141px;
    background: #000;
    border: 1px solid #42D0D2;
    box-sizing: border-box;
    box-shadow: 0px 0px 80px rgba(66, 208, 210, 0.4);
}

.prediction__warning {
    margin-bottom: 20px;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 18px;
    line-height: 21px;
}

.prediction__describe {
    margin-bottom: 29px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
}

.prediction__analysis {
    width: calc(100% - 60px);
    margin: 0 30px;
    margin-top: 40px;
    padding: 70px 65px 111px 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid #42D0D2;
    box-sizing: border-box;
    box-shadow: 0px 0px 80px rgba(66, 208, 210, 0.4);
    background: #000;
}

.prediction__analysis-img {
    width: 70%;
}

.prediction__analysis-text {
    width: 275px;
    margin-left: 15px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
}

/* prize */
.prize__text {
    max-width: 405px;
    margin-bottom: 20px;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 18px;
    line-height: 21px;
}

.prize__img {
    position: absolute;
}

.prize__img_top {
    top: 81px;
    left: 601px;
    z-index: 2;
}

.prize__img_mid {
    top: 79px;
    left: 811px;
}

.prize__img_bot{
    top: 205px;
    left: 861px;
}

/* instruction modal */
.instruction-modal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0);
    visibility: hidden;
    z-index: 150;
    transition: .5s;
}

.instruction-modal_active {
    background: rgba(0, 0, 0, .7);
    visibility: visible;
}

.instruction-modal__window {
    max-width: 607px;
    width: 100%;
    padding: 57px 88px 81px 88px;
    margin: 0 20px;
    position: relative;
    background: url("./../img/instruction/insruction-modal-bg.png") no-repeat center;
    background-size: 100% 100%;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 80px rgba(255, 255, 255, 0.55));
    transition: .5s;
    transform: translateY(-1000px);
}

.instruction-modal__window_active {
    transform: translateY(0);
}

.instruction-modal__title {
    margin-bottom: 17px;
    font-family: "Blender Pro", sans-serif;
    font-weight: normal;
    font-size: 34px;
    line-height: 41px;
    text-align: center;
    text-transform: uppercase;
}

.instruction-modal__text {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 155%;
    text-align: center;
}

.instruction-modal__button {
    width: 293px;
    height: 59px;
    margin: 0 auto;
    margin-bottom: 19px;
}

.instruction-modal__subscribe {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
    mix-blend-mode: normal;
    opacity: 0.9;
}

.instruction-modal__blue {
    color: #42D0D2;
}

.instruction-modal__close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.instruction-modal__close::before,
.instruction-modal__close::after {
    content: "";
    width: 18px;
    height: 2px;
    background: #aaa;
    position: absolute;
    left: 50%;
    margin-left: -9px;
    top: 50%;
    margin-top: -1px;
    cursor: pointer;
}

.instruction-modal__close::before {
    transform: rotate(45deg);
}

.instruction-modal__close::after {
    transform: rotate(-45deg);
}

/* video-preview */
.video-preview {
    width: 100%;
    height: 292px;
    margin-bottom: 51px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.video-preview__start {
    width: 58px;
    height: 58px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-sizing: border-box;
    cursor: pointer;
    transition: .3s;
}

.video-preview__start:hover {
    opacity: 0.7;
}

.video-preview__start-img {
    margin-left: 5px;
}

.video-preview__img {
    width: 98%;
    height: 96%;
    position: absolute;
    object-fit: cover;
}

.video-preview__img-border {
    width: 100%;
    height: 100%;
    position: absolute;
}

.video-preview__text {
    max-width: 232px;
    margin: 0 auto;
    position: relative;
    font-family: "Blender Pro", sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #fff;
}


















@media screen and (max-width: 1261px) {
    .prize__img_top {
        left: 501px;
    }

    .prize__img_mid {
        left: 711px;
    }

    .prize__img_bot{
        left: 761px;
    }
}

@media screen and (max-width: 1215px) {
    .instruction__window_3,
    .instruction__window_1 {
        zoom: 0.8;
        width: 120vw;
    }
}

@media screen and (max-width: 1168px) {
    .instruction__describe {
        margin-left: 15px;
        margin-right: 15px;
    }

    .instruction__window_5 {
        padding-left: 35px;
    }

    .prize__img_top {
        top: 180px;
        left: 651px;
    }

    .prize__img_mid {
        left: 811px;
    }

    .prize__img_bot{
        left: 891px;
    }

    .prize__img {
        zoom: 0.7;
    }
}

@media screen and (max-width: 970px) {
    .instruction__window_3,
    .instruction__window_1 {
        zoom: 0.75;
        width: 130vw;
    }
}

@media screen and (max-width: 993px) {
    .instruction {
        padding: 0 20px;
        background: #000;
    }

    .step {
        max-width: 623px;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        margin-top: 60px;
        position: static;
    }

    .step__manager {
        display: none;
    }

    .step__block {
        height: auto;
        padding-left: 0;
    }

    .step__box {
        min-height: 66px;
        padding: 0;
        display: flex;
        align-items: center;
    }

    .step__box .sum {
        display: flex;
        align-items: center;
        font-size: 54px;
    }

    .instruction__window_3,
    .instruction__window_1 {
        max-width: 623px;
        width: 100%;
        padding: 30px 40px 40px 30px;
        margin: 0 auto;
        margin-top: 36px;
        background: #000;
        border: 1px solid #42D0D2;
        zoom: 1;
    }

    .instruction__window_3 .instruction__content-left,
    .instruction__window_1 .instruction__content-left {
        display: none;
    }

    .instruction__content-right {
        padding-top: 0;
    }

    .instruction__content-right {
        max-width: none;
    }

    .instruction__right-text {
        max-width: 403px;
    }

    .instruction__right-name {
        min-width: 123px;
    }

    .instruction__window_2 {
        display: none;
    }

    .instruction__describe {
        max-width: 623px;
        margin: 0 auto;
        margin-top: 36px;
        padding: 20px 38px 40px 30px;
    }

    .instruction__describe-button {
        width: 225px;
        height: 67px;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: #fff;
        font-weight: bold;
        font-size: 12px;
        line-height: 19px;
        background: url("../img/instruction/button.png") no-repeat center;
        background-size: 100% 100%;
    }

    .instruction__step-3-btn {
        display: none;
    }

    .instruction__window_4 {
        max-width: 623px;
        margin: 0 auto;
        margin-top: 36px;
        padding: 30px 30px 37px 30px;
        border: 1px solid #42D0D2;
        box-sizing: border-box;
        box-shadow: 0px 0px 80px rgba(66, 208, 210, 0.4);
    }

    .prediction {
        margin-top: 0px;

    }

    .prediction__result {
        width: auto;
        padding: 0;
        padding-bottom: 15px;
        position: static;
        display: flex;
        align-items: flex-start;
        box-shadow: none;
        border: none;
        background: none;
        border-bottom: 1px solid #42D0D2;
    }

    .prediction__describe {
        margin-bottom: 0;
    }

    .prediction__top {
        max-width: 298px;
    }

    .prediction__result-img {
        max-width: 157px;
        margin-right: 30px;
        margin-left: auto;
        image-rendering: -webkit-optimize-contrast;
    }

    .prediction__analysis {
        width: 100%;
        padding: 0;
        margin: 0;
        margin-top: 15px;
        flex-direction: column-reverse;
        border: none;
        background: transparent;
        box-shadow: none;
    }

    .prediction__analysis-text {
        max-width: 465px;
        width: 100%;
        margin-left: 0;
        margin-bottom: 20px;
    }

    .prediction__analysis-img {
        width: 100%;
    }

    .instruction__window_5 {
        max-width: 623px;
        width: 100%;
        padding: 30px 30px 40px 30px;
        margin: 0 auto;
        margin-top: 36px;
        background: #000;
        border: 1px solid #42D0D2;
        box-sizing: border-box;
        box-shadow: 0px 0px 80px rgba(66, 208, 210, 0.4);
    }

    .prize__img {
        position: static;
    }

    .prize__text {
        max-width: 242px;
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
    }

    .prize__top {
        padding-bottom: 23px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid #42D0D2;
    }

    .prize__img_top {
        margin-right: 40px;
    }

    .prize__middle {
        margin-top: 24px;
        margin-bottom: 30px;
    }

    .prize__middle .prize__text {
        max-width: none;
    }

    .prize__bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .prize__img_bot {
        margin-right: 30px;
    }

    .instruction__manager {
        max-width: 623px;
        margin: 0 auto;
        display: block;
        margin-top: 29px;
    }

    .instruction__manager-content {
        width: 222px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}


@media screen and (max-width: 600px) {
    .prediction__result-img {
        margin-left: 15px;
    }
}

@media screen and (max-width: 540px) {
    .prize__top {
        flex-direction: column;
    }

    .prize__bottom {
        flex-direction: column;
    }

    .prize__text {
        max-width: none;
        font-size: 14px;
    }

    .prize__img {
        margin: 0 auto;
        display: block;
    }

    .prize__img_top {
        max-width: 235px;
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .instruction__window_4 {
        padding: 20px;
    }

    .prediction__result {
        display: block;
    }

    .prediction__top {
        max-width: none;
        margin-bottom: 25px;
    }

    .prediction__result-img {
        max-width: 234px;
        margin: 0 auto;
        display: block;
    }

    .prediction__warning {
        font-size: 14px;
    }

    .prediction__describe {
        font-size: 14px;
    }

    .prediction__analysis-text {
        font-size: 14px;
    }

    .instruction-modal__window {
        max-height: 90vh;
        padding: 15px;
        padding-top: 45px;
        overflow: auto;
        background: #fff;
    }

    .instruction-modal__button {
        width: 100%;
    }
}

@media screen and (max-width: 490px) {
    .instruction__window_3,
    .instruction__window_1 {
        padding: 20px;
    }

    .instruction__right-name {
        min-width: 102px;
        zoom: 0.8;
    }

    .instruction__right-text {
        font-size: 14px;
        line-height: 16px;
    }

    .instruction__right-title {
        font-size: 14px;
        line-height: 16px;
    }

    .instruction__describe {
        min-height: 0;
        margin-top: 20px;
        padding: 20px 17px 30px 20px;
        font-size: 14px;
        line-height: 16px;
    }

    .instruction__describe-button {
        display: none;
    }
}
