span.value {
    white-space: nowrap;
}

/* 
@media (max-width: 450px){
    text#SvgjsText2913 {
        font-size: 34px;
    }
    text#SvgjsText2914 {
        font-size: 34px;
    }
    text#SvgjsText1108 {
        font-size: 12px;
    }
    .chart-data {
        top: 132px;
    }
} 
*/

.main-button {
    width: 307px;
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: url("./../img/blitz-button.png") no-repeat center;
    background-size: 100% 100%;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    outline: none;
    transition: .3s;
}

.main-button::before {
    content: "";
    width: 37px;
    height: 37px;
    background: url("../img/blitz-small-ring.png") no-repeat center;
    position: absolute;
    left: 0;
    bottom: 9px;
    z-index: -1;
}

.main-button::after {
    content: "";
    width: 61px;
    height: 61px;
    background: url("../img/blitz-big-ring.png") no-repeat center;
    position: absolute;
    right: 0;
    top: -5px;
    z-index: -1;
}

.main-button::before,
.main-button::after {
    animation: rotateRing 13s infinite linear;
}

.stock .box div {
    width: 100%;
    height: 100%;
}

.stock article {
    width: 100%;
}

.col_right {
    position: static;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}
.col_right .cover {
    margin:0
}

.text-predict {
    text-align: center;
}

.header-small_screen-mobile {
    justify-content: start;
}

.header-small_screen-mobile-block {
    display: none;
}

.rating-table td,
.rating-table th {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.box-rating__cell_status {
    max-height: 43px;
}

.box-rating__wrapper {
    width: 30px;
    height: 30px;
    display: inline-block;
    position: relative;
}

.box_rating {
    max-height: 495px;
}

.box_rating::before {
    left: -8px;
}

.box-rating__avatar {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
}

.box-rating__status {
    width: 12px;
    height: 12px;
    position: absolute;
    right: -1px;
    bottom: -1px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
    border-radius: 50%;
}

.box-rating__status-img {
    width: 6px;
}

@media (min-width: 370px) {
    .header-small_screen-mobile {
        justify-content: space-around;
    }

    .header-small_screen-mobile-block {
        display: flex;
    }
}

@media (min-width: 768px) {
    .results-wrapper {
        max-width: 100%;
        flex: 0 0 100%;
    }
}
@media (max-width: 575px) {
    .text-predict {
        text-align: right;
    }
    .row-predict {
        width: 100%;
    }
    .rules-list {
        padding-right: 10px;
    }
    .table-game tbody tr .inline-text__subtitle {
        max-width: 100%;
        white-space: pre-wrap;
    }
    .result-block tbody tr td {
        padding: 10px 20px !important;
    }

    .result-block table {
        border-collapse: separate ;
        border-spacing: 0 20px;
    }

    .result-block .inline-text {
        padding-left: 0!important;
        display: flex;
        align-items: center;
    }
    .result-block .inline-text .inline-text__subtitle {
        margin-left: 5px;
    }
    .modal-window >div {
        width: 93%;
    }
    
    table td .cell__title{
        font-size: 14px;
    }


    table td > div .subtitle_middle {
        margin-right: 5px;
    }
    table td .inline-text__subtitle {
        max-width: 100%;
        white-space: pre-wrap;
    }

    .outcome .empty {
        display: none;
    }

    .outcome .outcome__title {
        font-weight: 600;
    }

    .outcome .cell__title {
        font-size: 16px;
    }
    .outcome .sum {
        font-weight: 600;
    }
    .text-right__mobile {
        text-align: right;
    }
}

.text-right__mobile {
    text-align: center;
} 