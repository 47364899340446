.bitcoin {
    padding-bottom: 70px;
    position: relative;
}

.bitcoin__info {
    max-width: 1500px;
    padding: 0 20px;
    margin: 0 auto;
    margin-bottom: 50px;
}

.bitcoin__dots-right {
    right: -86px;
    top: 133px;
}

.container-fluid_bitcoin {
    position: relative;
    z-index: 2;
}

.bitcoin__title {
    font-family: "Blender Pro", sans-serif;
    font-weight: normal;
    font-size: 64px;
    line-height: 77px;
}

.bitcoin__rule-title {
    margin-bottom: 10px;
    font-family: "Blender Pro", sans-serif;
    font-weight: normal;
    font-size: 44px;
    line-height: 53px
}

.bitcoin__rules {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
}

.bitcoin__rule {
    width: calc(50% - 12px);
    padding: 10px 0;
    padding-right: 5px;
    min-height: 70px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    background: url("./../img/blitz-elem-bg.png") no-repeat center;
    background-size: 100% 100%;
}

.bitcoin__rule-numb {
    min-width: 35px;
    align-self: flex-start;
    font-family: "Blender Pro", sans-serif;
    font-weight: normal;
    font-size: 34px;
    line-height: 41px;
}

.bitcoin__btn-wrapper {
    width: 100%;
    height: 281px;
    margin-bottom: 70px;
    position: relative;
    overflow: hidden;
}

.bitcoin__btn {
    width: 1064px;
    height: 100%;
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -532px;
    top: 0;
    background: url("./../img/bitcoin-btn.png") no-repeat center;
    background-size: 100%;
}

.bitcoin__bets-window {
    min-height: 768px;
    padding: 10px 20px;
    position: relative;
    border: 1px solid #42D0D2;
    overflow: hidden;
}

.bitcoin__bets-title {
    margin-bottom: 11px;
    font-family: "Blender Pro", sans-serif;
    font-weight: normal;
    font-size: 64px;
    line-height: 77px;
}

.bitcoin__select-wrapper {
    width: 150px;
    position: absolute;
    top: 59px;
    right: 46px;
    z-index: 3;
}

.bitcoin__stock-top {
    padding-left: 20px;
    padding-right: 64px;
    margin-bottom: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bitcoin__stock-bottom {
    max-height: 638px;
    padding-right: 15px;
    overflow-y: auto;
}

.bitcoin__stock-row {
    padding-left: 20px;
    padding-right: 40px;
    padding-bottom: 15px;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(151, 151, 151, 0.4);
}

.bitcoin__stock-row_active {
    margin: 5px 0;
    border: 1px solid #42D0D2;
    box-sizing: border-box;
    box-shadow: 0 0 30px rgba(66, 208, 210, 0.6);
}

.bitcoin__stock-cell {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
}

.bitcoin__stock-cell_numb {
    min-width: 63px;
    max-width: 63px;
}

.bitcoin__stock-cell_course {
    min-width: 75px;
}

.bitcoin__stock-cell_amount {
    min-width: 105px;
    font-family: "Blender Pro", sans-serif;
    font-weight: 900;
    font-size: 26px;
    line-height: 31px;
}

.bitcoin__stock-cell_date {
    min-width: 74px;
}

.bitcoin__stock-cell_time {
    min-width: 40px;
}

.bitcoin__stock-cell_final {
    min-width: 122px;
}

.bitcoin__stock-cell_name {
    min-width: 90px;
}

.bitcoin__stock-cell_action {
    min-width: 85px;
}

.bitcoin__stock-cell_blue {
    color: #42D0D2;
}

.bitcoin__stock-cell_head {
    font-family: "Blender Pro", sans-serif;
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
}

.bitcoin__stock-btn {
    padding: 0;
    font-family: "Blender Pro", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #42D0D2;
}

.bitcoin__stock-btn_active {
    color: #fff;
}

.bitcoin__stock-cell-name {
    display: none;
}

.bitcoin__select {
    width: 100%;
    position: relative;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
}

.bitcoin__select-selected {
    padding: 0 6px;
    position: relative;
}

.bitcoin__select-selected::after {
    content: "";
    min-width: 20px;
    height: 10px;
    position: absolute;
    top: 50%;
    margin-top: -5px;
    right: 0;
    background: url("./../img/down-3.svg") no-repeat center;
}

.bitcoin__select-list {
    width: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 28px;
    left: 0;
    display: block;
    background: #fff;
    color: #000;
    border-bottom: 1px solid #42D0D2;
    border-left: 1px solid #42D0D2;
    border-right: 1px solid #42D0D2;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    box-shadow: 0 10px 30px rgba(66, 208, 210, 0.6);
}

.bitcoin__select-list_active {
    opacity: 1;
    visibility: visible;
}

.bitcoin__select-elem {
    width: 100%;
    margin: 3px 0;
    padding: 6px;
    padding-left: 16px;
    position: relative;
    display: block;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.4);
}

.bitcoin__select-elem:last-child {
    margin-bottom: 0;
}

.bitcoin__select-elem_active::before,
.bitcoin__select-elem_active::after {
    content: "";
    height: 2px;
    position: absolute;
    bottom: 13px;
    background: #000;
}

.bitcoin__select-elem_active::before {
    width: 3px;
    left: 3px;
    transform: rotate(60deg);
}

.bitcoin__select-elem_active::after {
    width: 10px;
    left: 3px;
    transform: rotate(130deg);
}

.bitcoin__switch {
    max-width: 685px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 54px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #fff;
}

.bitcoin__switch-btn {
    width: 50%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("./../img/bitcoin-switch-bg.png") no-repeat center;
    background-size: 100% 100%;
    filter: drop-shadow(0px -5px 10px rgba(255, 255, 255, 0.4));
    font-family: "Blender Pro", sans-serif;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #42D0D2;
    transition: .2s;
}

.bitcoin__switch-btn_active {
    background: url("./../img/bitcoin-switch-bg-active.png") no-repeat center;
    background-size: 100% 100%;
    filter: none;
    color: #FFFFFF;
}

.bitcoin__bets-title_game {
    padding-left: 20px;
    padding-top: 20px;
    margin-bottom: 40px;
}

.bitcoin__game {
    padding: 0 20px;
}

.bitcoin__game-top {
    padding-right: 20px;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(151, 151, 151, .4);;
}

.bitcoin__game-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bitcoin__game-left {
    width: 30%;
    height: 100%;
    border-bottom: 2px solid rgba(255, 255, 255, .5);
}

.bitcoin__game-medium {
    width: 36%;
    height: 100%;
    padding: 0 20px;
    margin: 0 10px;
    position: relative;
}

.bitcoin__game-bg {
    height: 632px;
    margin-left: -50%;
    top: -21px;
    position: absolute;
    left: 50%;
    filter: drop-shadow(0px 0px 30px rgba(66, 208, 210, 0.6));
}

.bitcoin__game-right {
    width: 30%;
    height: 100%;
    border-bottom: 2px solid rgba(255, 255, 255, .5);
}

.bitcoin__game-block_head {
    border: none;
}

.bitcoin__game-bottom {
    max-height: 572px;
    overflow-y: auto;
}

.bitcoin__game-row {
    height: 48px;
    padding-right: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bitcoin__game-cell {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bitcoin__game-cell_head {
    position: relative;
    font-family: "Blender Pro", sans-serif;
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
}

.bitcoin__game-cell_body {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center
}

.bitcoin__game-cell_forecast,
.bitcoin__game-cell_name {
    width: 50%;
}

.bitcoin__game-cell_forecast-results,
.bitcoin__game-cell_name-results,
.bitcoin__game-cell_deviation-results {
    width: 33%;
}

.bitcoin__game-cell_date,
.bitcoin__game-cell_time,
.bitcoin__game-cell_amount,
.bitcoin__game-cell_numb {
    width: 25%;
}

.bitcoin__game-cell_amount {
    align-items: baseline;
    font-size: 12px;
    line-height: 15px;
}

.bitcoin__game-cell_blue {
    margin-right: 5px;
    font-family: "Blender Pro", sans-serif;
    font-style: italic;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #42D0D2;
}

.bitcoin__game-cell-name {
    display: none;
}


.bitcoin__bets-window_results {
    min-height: 815px;
    margin-top: 37px;
}

.bitcoin__game-row_results {
    margin: 10px 0;
}

.bitcoin__game-left_results {
    width: 24%;
    position: relative;
    border: none;
    background: url("./../img/bitcoin-win-bg.png") no-repeat center;
    background-size: 100% 100%;
}

.bitcoin__game-medium_results {
    width: 50%;
}

.bitcoin__game-right_results {
    width: 24%;
    position: relative;
    border: none;
    background: url("./../img/bitcoin-lose-bg.png") no-repeat center;
    background-size: 100% 100%;
}

.bitcoin__no-winner {
    background: none;
}

.bitcoin__game-block_head {
    background: none;
}

.bitcoin__game-left_results::before,
.bitcoin__game-right_results::before {
    width: 184px;
    position: absolute;
    top: -64px;
    left: 50%;
    margin-left: -92px;
    font-family: "Blender Pro", sans-serif;
    font-style: italic;
    font-weight: 250;
    font-size: 36px;
    line-height: 43px;
    text-align: center;;
}

.bitcoin__game-block_head.bitcoin__game-left_results::before {
    content: "WIN";
    color: #42D0D2;
}

.bitcoin__game-block_head.bitcoin__game-right_results::before {
    content: "LOSS";
    color: #D7263D;
}

.bitcoin__game-block_head-no-winner.bitcoin__game-left_results::before,
.bitcoin__game-block_head-no-winner.bitcoin__game-right_results::before {
    display: none;
}

.bitcoin__game-bg_results {
    width: 100%;
    height: 630px;
}

.bitcoin__game-cell_course {
    width: 35%;
    font-family: "Blender Pro", sans-serif;
    font-style: italic;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #42D0D2;
}

.bitcoin__game-cell_head.bitcoin__game-cell_course {
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
}

.bitcoin__bets-title_results {
    margin-bottom: 75px;
}

.bitcoin__game-result {
    display: none;
}

.bitcoin-modal__window {
    width: 331px;
}

.bitcoin-modal__content {
    padding-right: 25px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.bitcoin-modal__input-box {
    width: 100%;
    height: 35px;
    padding-left: 11px;
    display: flex;
    align-items: center;
    background: url("./../img/bitcoin-input.png") no-repeat center;
    background-size: 100% 100%;
}

.bitcoin-modal__input-wrapper {
    width: 118px;
    margin-bottom: 33px;
}

.bitcoin-modal__input-title {
    margin-bottom: 10px;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}

.bitcoin-modal__input-manage {
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.bitcoin-modal__arrow {
    width: 10px;
    height: 6px;
    padding: 2px 0;
    margin: 2px 0;
    outline: none;
}

.bitcoin-modal__arrow:focus {
    outline: none;
}

.bitcoin-modal__arrow_up {
    background: url("./../img/down-3.svg") no-repeat center;
    transform: rotate(180deg);
}

.bitcoin-modal__arrow_down {
    background: url("./../img/down-3.svg") no-repeat center;
}

.bitcoin-modal__inputs {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bitcoin-modal__input {
    width: 14px;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
}

.bitcoin-modal__course-dollar {
    margin-right: 3px;
}

.bitcoin-modal__input_year {
    width: 28px;
}

.bitcoin-modal__input_course {
    width: 70px;
}

.bitcoin-modal__input_bet {
    width: 50px;
}

.bitcoin-modal__input-additional {
    width: 37px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #42D0D2;
}

.bitcoin-modal__inputs_bet {
    width: 100%;
    justify-content: space-between;
}

.bitcoin-modal__plug {
    cursor: pointer;
}

.bitcoin-modal__plug_empty {
    opacity: 0.4;
}

.bitcoin__calendar {
    width: 284px;
    min-height: 278px;
    position: absolute;
    left: 0;
    top: 72px;
    z-index: 4;
    transition: .2s;
    visibility: hidden;
    opacity: 0;
}

.bitcoin__calendar_active {
    visibility: visible;
    opacity: 1;
}

.bitcoin-modal-icon path {
    stroke: #000;
    fill: none;
    opacity: .2;
}

.bitcoin-modal-icon_active path {
    stroke: #42D0D2;
    opacity: 1;
}

.bitcoin-modal__inputs_date,
.bitcoin-modal__inputs_time {
    width: 100%;
    padding-right: 10px;
    justify-content: space-between;
}


.bitcoin__time-list {
    width: 80px;
    height: 110px;
    position: absolute;
    right: 25px;
    top: 70px;
    visibility: hidden;
    opacity: 0;
}

.bitcoin__time-list_active {
    visibility: visible;
    opacity: 1;
}

.bitcoin__game-empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    line-height: 16px;
    opacity: 0.3;
    text-align: center;
}

.bitcoin__game-empty-text {
    max-width: 250px;
}

.bitcoin__stock-comma {
    display: none;
}

















@media screen and (max-width: 1070px) {
    .bitcoin__game {
        padding-right: 0;
        padding-left: 0;
    }

    .bitcoin__bets-title_game {
        padding-left: 0;
    }

    .bitcoin__game-right {
        width: 26%;
    }

    .bitcoin__game-medium {
        width: 42%;
    }

    .bitcoin__game-medium_results {
        width: 52%;
        padding: 0 10px;
    }

    .bitcoin__game-cell-value_course,
    .bitcoin__game-cell_blue  {
        font-size: 16px;
    }
}

@media screen and (max-width: 1050px) {
    .bitcoin__game-medium_results {
        width: 50%;
    }
}



@media screen and (max-width: 860px) {
    .bitcoin__container {
        padding: 0 15px;
    }

    .bitcoin__rules {
        flex-direction: column;
    }

    .bitcoin__rule {
        width: 100%;
    }
}

@media screen and (max-width: 750px) {
    .bitcoin__title {
        font-size: 44px;
        font-weight: normal;
        line-height: 120%;
    }

    .bitcoin__rule {
        background: url("./../img/bitcoin-rule-bg-small.png") no-repeat center;
        background-size: 100% 100%;
    }

    .bitcoin__rule-title {
        margin-bottom: 23px;
        font-size: 24px;
        line-height: 29px
    }

    .bitcoin__rule-numb {
        font-size: 34px;
        line-height: 41px;
    }

    .bitcoin__rule-text {
        font-size: 12px;
        line-height: 14px;
    }

    .bitcoin__rules {
        margin-bottom: 33px;
    }

    .bitcoin__switch {
        margin-bottom: 44px;
        flex-direction: column;
        align-items: center;
        border: none;
    }

    .bitcoin__switch-btn {
        width: 100%;
        margin: 9px 0;
        border-bottom: 1px solid #fff;
    }

    .bitcoin__bets-title {
        padding-left: 20px;
        margin-bottom: 8px;
        font-size: 44px;
        line-height: 53px;
    }

    .bitcoin__select-wrapper {
        padding-left: 15px;
        margin-bottom: 0;
        position: relative;
        display: inline-block;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    .bitcoin__bets-window {
        padding-right: 0;
        padding-left: 0;
    }

    .bitcoin__stock-top {
        display: none;
    }

    .bitcoin__stock-bottom {
        padding-right: 20px;
        padding-left: 20px;
    }

    .bitcoin__stock-row_active {
        border: none;
        box-shadow: none;
    }

    .bitcoin__stock-row {
        padding: 0;
        margin-bottom: 20px;
        flex-direction: column;
        border: none;
    }

    .bitcoin__stock-cell {
        width: 100%;
        height: 40px;
        max-width: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    .bitcoin__stock-cell_name {
        order: -1;
    }

    .bitcoin__stock-cell_amount {
        order: 8;
    }

    .bitcoin__stock-cell_final {
        order: 9;
    }

    .bitcoin__stock-cell_action {
        height: 50px;
        justify-content: center;
        border-top: 1px solid #42D0D2;
        border-bottom: 1px solid #42D0D2;
        order: 10;
    }

    .bitcoin__stock-cell_name-active {
        width: calc(100% + 20px);
        padding-left: 10px;
        padding-right: 10px;
        position: relative;
        border: 1px solid #42D0D2;
        box-sizing: border-box;
        box-shadow: 0 0 30px rgba(66, 208, 210, 0.6);
        background: #000;
    }

    .bitcoin__stock-cell-name {
        display: inline;
        font-family: "Blender Pro", sans-serif;
        font-weight: 900;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
    }

    .bitcoin__stock-value {
        font-size: 14px;
        line-height: 16px;
    }

    .bitcoin__game {
        padding-left: 0;
    }

    .bitcoin__bets-title {
        padding-left: 13px;
    }

    .bitcoin__game-bg {
        display: none;
    }

    .bitcoin__game-top {
        display: none;
    }

    .bitcoin__game-bottom {
        padding-left: 13px;
        padding-top: 15px;
    }

    .bitcoin__game-row {
        height: auto;
        padding-right: 10px;
        flex-direction: column;
        border: none;
    }

    .bitcoin__game-medium {
        background: #000000;
        border: 1px solid #42D0D2;
        box-shadow: 0 0 30px rgba(66, 208, 210, 0.6);
        order: -1;
    }

    .bitcoin__game-block {
        width: 100%;
    }

    .bitcoin__game-cell-name {
        display: inline;
    }

    .bitcoin__game-cell_numb {
        display: none;
    }

    .bitcoin__game-medium {
        height: 70px;
        margin-bottom: 22px;
        padding: 0 13px;
    }

    .bitcoin__game-cell_date,
    .bitcoin__game-cell_time,
    .bitcoin__game-cell_amount {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .bitcoin__game-cell-name {
        margin-bottom: 5px;
        font-family: "Blender Pro", sans-serif;
        font-weight: 900;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        text-transform: uppercase;
    }

    .bitcoin__game-left,
    .bitcoin__game-right {
        width: calc(100% - 20px);
        margin: 0 auto;
        margin-bottom: 15px;
        padding-bottom: 15px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255 , .2);
    }

    .bitcoin__game-cell_forecast,
    .bitcoin__game-cell_name,
    .bitcoin__game-cell_deviation {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .bitcoin__game-cell_forecast {
        margin-bottom: 4px;
    }

    .bitcoin__game-cell_deviation {
        margin-top: 3px;
        order: 3;
    }

    .bitcoin__select-wrapper_game {
        margin-left: -6px;
    }

    .bitcoin__game-left_results,
    .bitcoin__game-right_results {
        background: none;
    }

    .bitcoin__game-cell_course {
        width: auto;
        padding-bottom: 7px;
        flex-direction: column;
        align-self: flex-end;
        order: 5;
    }

    .bitcoin__game-cell-name {
        color: #fff;
    }

    .bitcoin__game-result {
        margin-bottom: 10px;
        display: block;
        align-self: flex-start;
        font-family: "Blender Pro", sans-serif;
        font-style: italic;
        font-weight: 250;
        font-size: 26px;
        line-height: 31px;
        text-transform: uppercase;
    }

    .bitcoin__game-result_win {
        color: #42D0D2;
    }

    .bitcoin__game-result_lose {
        color: #D7263D;
    }

    .bitcoin__game-empty-text {
        padding-top: 100px;
    }

    .bitcoin__stock-comma {
        display: inline;
    }
}

@media screen and (max-width: 370px) {
    .bitcoin__stock-bottom {
        padding-right: 14px;
        padding-left: 14px;
    }

    .bitcoin__stock-cell-name {
        font-size: 12px;
    }
}