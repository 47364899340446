button:focus {
  outline: none;
}

.up-down {
  padding-bottom: 65px;
}

.up-down__container {
  max-width: 1200px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: content-box;
}

.up-down__header {
  padding-top: 41px;
  padding-bottom: 22px;
}

.up-down__main {
  display: flex;
  justify-content: space-between;
  color: white;
}

.up-down__main-left {
  width: 100%;
}

.up-down__title {
  margin-bottom: 8px;
  font-family: 'Blender Pro';
  font-weight: 250;
  font-size: 64px;
  line-height: 77px;
}

.up-down__sutitle {
  margin-bottom: 10px;
  font-family: 'Blender Pro';
  font-weight: 250;
  font-size: 44px;
  line-height: 53px;
}

.up-down__rules-list {
  display: block;
  max-width: 600px;
  width: 100%;
}

.up-down__rules-elem {
  padding: 8px 0;
  margin-bottom: 16px;
  width: 100%;
  max-width: 600px;
}

.up-down__img {
  width: 494px;
  margin-left: 20px;
}

.up-down__manage {
  color: #fff;
}

.up-down__manage-title {
  margin-bottom: 84px;
  font-family: 'Blender Pro';
  font-weight: 500;
  font-size: 64px;
  line-height: 77px;
  text-align: center;
}

.up-down__manage-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}

.up-down__manage-radios {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.up-down__manage-radio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 218px;
  height: 220px;
  opacity: .6;
  outline: none;
  border: none;
}

.up-down__manage-radio:hover {
  opacity: 1;
}

.up-down__manage-radio_down {
  margin-left: 80px;
  margin-right: auto;
}

.up-down__manage-radio_checked {
  opacity: 1;
}

.up-down__manage-start:disabled {
  opacity: 0.6;
}

.up-down__info {
  margin-bottom: 53px;
  margin-top: 40px;
}

.up-down__info .bitcoin__info {
  padding: 0;
}

.up-down__manage-image {
  position: absolute;
  left: 0;
  bottom: 112px;
  width: 100%;
  /* height: 421px; */
  padding-top: 7.25%;
}

.up-down__manage-image img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.up-down__game {
  position: relative;
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
  border: 1px solid #42D0D2;
  z-index: 1;
}

.up-down__game_red {
  border-color: #D7263D;
}

.up-down__date {
  padding-top: 45px;
  padding-left: 37px;
  margin-bottom: 37px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(255, 255, 255, .4);
}

.up-down__date-number {
  color: white;
}

.up-down__game-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
}

.up-down__game-left {
  position: relative;
  display: flex;
  padding-left: 48px;
}

.up-down__game-line {
  position: absolute;
  left: 48px;
  top: 56px;
  width: 430px;
  height: 1px;
  background-color: #42D0D2;
}

.up-down__game-line::after {
  content: "";
  position: absolute;
  left: 0;
  top: -2px;
  width: 255px;
  height: 2px;
  background-color: #42D0D2;
}

.up-down__game-block {
  width: 180px;
  color: white;
}

.up-down__game-block:last-child {
  width: auto;
}

.up-down__game-subtitle {
  margin-bottom: 20px;
  font-family: 'Blender Pro';
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
}

.up-down__subtitle {
  max-width: 534px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 35px;
  text-align: center;
}

.up-down__game-value {
  font-family: 'Roboto Condensed';
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: white;
}

.up-down__game-arrow {
  display: flex;
  align-items: flex-end;
  margin-right: 5px;
  position: relative;
  height: 12px;
  width: 1px;
  background: #51CB20;
  border-radius: 20px;
  transform: rotate(180deg);
}

.up-down__game-arrow::before,
.up-down__game-arrow::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 5px;
  background: #51CB20;
  transform-origin: bottom center;
  border-radius: 20px;
}

.up-down__game-arrow::before {
  transform: rotate(-45deg) translateY(1px);
}

.up-down__game-arrow::after {
  transform: rotate(45deg) translateY(1px);
}


.up-down__game-price {
  display: flex;
  align-items: center;
  margin-top: 4px;
  font-family: 'Roboto Condensed';
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #51CB20;
}

.up-down__game-price_red {
  color: #D7263D;
}

.up-down__game-price_red .up-down__game-arrow {
  transform: none;
  background: #D7263D;
}

.up-down__game-price_red .up-down__game-arrow::before,
.up-down__game-price_red .up-down__game-arrow::after {
  background: #D7263D;
}


.up-down__game-value_direction {
  display: inline-block;
  padding: 4px 16px;
  border-radius: 4px;
  background: #51CB20;
  font-style: italic;
  font-weight: 500;
}

.up-down__game-direction-text {
  padding-right: 6px;
}

.up-down__game-value_direction img {
  /* margin-right: 5px; */
  margin-bottom: 2px;
  min-height: 16px;
  padding-right: 5px;
}

.up-down__game-value_down {
  padding: 4px 12px;
  background: #D7263D; 
}

.up-down__game-value_down img {
  transform: rotate(180deg);
  margin-right: 5px;
}

.up-down__time {
  padding-right: 73px;
}

.up-down__time-title {
  font-family: 'Blender Pro';
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: white;
}

.up-down__time-value {
  font-family: 'Blender Pro';
  font-weight: 900;
  font-size: 140px;
  line-height: 168px;
  color: #42D0D2;
}

.up-down__time-number {
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #42D0D2;
}

.up-down__row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  font-family: 'Blender Pro ';
  font-style: italic;
  font-weight: 251;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  color: #42D0D2;
  background: radial-gradient(50% 50% at 50% 50%, rgba(66, 208, 210, 0) 24.48%, rgba(66, 208, 210, 0.24) 100%);
}

.up-down__row_red {
  background: radial-gradient(50% 50% at 50% 50%, rgba(215, 38, 61, 0) 24.48%, rgba(215, 38, 61, 0.24) 100%);
  color: #D7263D;
}

.up-down__btns {
  display: flex;
  align-items: center;
  padding-right: 15px;
}

.up-down__btn {
  position: relative;
  width: 224px;
  height: 56px;
  font-family: 'Blender Pro';
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
}

.up-down__btn span {
  position: relative;
}

.up-down__btn-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.up-down__btn_again {
  margin-right: 20px;
  color: #42D0D2;
}

.up-down__btn_gift {
  color: #fff;
}

.up-down__chart {
  margin-top: 30px;
}


@media screen and (max-width: 1024px) {
  .up-down__main {
    flex-direction: column;
  }

  .up-down__rules-list {
    max-width: none;
  }

  .up-down__rules-elem {
    max-width: none;
    padding-right: 20px;
  }

  .up-down__img {
    margin: 0 auto;
  }

  .up-down__manage-title {
    margin-bottom: 32px;
    font-size: 44px;
    line-height: 53px;
  }

  .up-down__game-content {
    flex-direction: column;
  }

  .up-down__time {
    padding: 0 20px;
  }

  .up-down__time-title {
    text-align: center;
  }

  .up-down__game-left {
    padding: 0 20px;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 46px;
  }

  .up-down__game-line {
    width: calc(100% - 40px);
    left: 20px;
  }

  .up-down__date {
    padding-left: 20px;
  }

  .up-down__manage-content {
    flex-direction: column;
  }

  .up-down__manage-radios {
    margin-bottom: 30px;
  }

  .up-down__manage-radio {
    margin: 0;
    width: calc(50% - 5px);
  }
}

@media screen and (max-width: 991px) {
  .up-down__manage-image {
    bottom: 149px;
  }
}

@media screen and (max-width: 767px) {
  .up-down__manage-image {
    bottom: 220px;
  }
}

@media screen and (max-width: 768px) {
  .up-down__container {
    padding: 0 20px;
  }

  .up-down__title {
    margin-bottom: 4px;
    font-size: 44px;
    line-height: 120%;
  }

  .up-down__sutitle {
    font-size: 24px;
    line-height: 29px
  }

  .up-down__manage-title {
    font-size: 44px;
    line-height: 53px;
  }

  .up-down__game-subtitle {
    font-size: 12px;
    line-height: 14px;
  }

  .up-down__game-value {
    font-size: 12px;
    line-height: 14px;
  }

  .up-down__game-line {
    top: 37px;
    width: 50%;
  }

  .up-down__game-line::after {
    width: 170%;
  }

  .up-down__game-block {
    width: 35%;
  }

  .up-down__game-block:last-child {
    width: 23%;
  }

  .up-down__time-title {
    font-size: 16px;
    line-height: 19px;
  }

  .up-down__time-value {
    font-size: 84px;
    line-height: 101px;
  }

  .up-down__manage-radios {
    margin-bottom: 10px;
  }
}


@media screen and (max-width: 425px) {
  .up-down__manage-image {
    bottom: 211px;
  }
}