.lines__main {
    width: 100%;
    height: 2px;
    background: #42D0D2;
}

.lines__mini {
    width: 50%;
    height: 2px;
    background: #42D0D2;
}


.football {
    color: #fff;
    font-family: "Blender Pro", sans-serif;
}

.football__main-right{
    width: 484px;
    padding-top: 60px;
    margin-left: 13px;
}

.football__container {
    max-width: 1215px;
    padding: 0 40px;
    margin: 0 auto;
}

.football__main {
    margin-bottom: 97px;
}
img{
    min-height: 4px;
}
.football_main-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.football__main-left {
    padding-top: 40px;
}

.football__title p {
    max-width: 700px;
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 72px;
    line-height: 84px;
    letter-spacing: -0.576px;
}

.football__prize-title p {
    margin-bottom: 7px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.032px;
}

.football__prize-amount p {
    margin-bottom: 34px;
    font-weight: bold;
    font-size: 44px;
    line-height: 53px;
    letter-spacing: 0.088px;
}

.football__prize-blue p{
    margin-bottom: 24px;
    font-size: 28px;
    line-height: 34px;
}

.football__button p {
    width: 250px;
    height: 64px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("./../img/share-page/button.png") no-repeat center;
    background-size: 116%;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.144px;
    color: #fff;
}

.football__main-link {
    color: #fff;
}

.football__main-link:hover {
    color: #fff;
}

.football__modal-call {
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.144px;
    color: #42D0D2 !important;
    cursor: pointer;
}

.football__img {
    margin-bottom: 25px;
    width: 100%;
    height: auto;
}

.football__time-title {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.128px;
}

.football__time {
    display: flex;
    align-items: flex-start;
}

.football__time-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 15px;
}

.football__time-numb {
    font-weight: bold;
    font-size: 56px;
    line-height: 67px;
    color: rgba(66, 208, 210, 0.0001);
    -webkit-text-stroke: 1px #42D0D2;
}

.football__time-name {
    margin-top: -8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.028px;
}

.football__subtitle p {
    margin-bottom: 45px;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.256px;
    text-transform: uppercase;
}

.football__instruction {
    margin-bottom: 100px;
}

.football__instruction-block {
    margin-bottom: 50px;
    display: flex;
    align-items: flex-start;
}

.football__instruction-left {
    min-width: 26px;
    height: 24px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: -0.272px;
    text-transform: uppercase;
}

.football__instruction-text {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.192px;
}

.football__instruction-img {
    margin-top: 13px;
    margin-bottom: 20px;
}

.football__instruction-link {
    margin-left: 7px;
}

.football__instruction-line {
    margin-top: 10px;
    margin-left: -5px;
}

.football__bottom {
    padding-bottom: 100px;
}

.football__bottom-time {
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.144px;
    color: #43D0D2;
    text-align: center;
}

.football__bottom-text p{
    max-width: 912px;
    margin: 0 auto;
    margin-bottom: 60px;
    font-weight: 500;
    font-size: 52px;
    line-height: 62px;
    text-align: center;
    letter-spacing: -0.416px;
}

.football__button_bottom {
    margin: 0 auto;
    display: block;
    outline: none;
}


.football__modal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    z-index: 100;
    transition: .5s;
    color: #000000;
}

.football__modal_active {
    background: rgba(0, 0, 0, .6);
    visibility: visible;
    opacity: 1;
}

.football__modal-window {
    max-width: 755px;
    width: 100%;
    /* height: 80%;
    max-height: 100%; */
    padding: 30px;
    padding-bottom: 45px;
    /* position: fixed; */
    height: auto;
    overflow-y: auto;
    background: #fff;
    transform: translateY(-200px);
    opacity: 0;
    transition: .5s;
}

.football__modal-window_active {
    transform: translateY(0);
    opacity: 1;
}

.football__modal-title {
    max-width: 392px;
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.06px;
}

.football__modal-text {
    width: 100%;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.112px;
}

.football__modal-link {
    color: #42D0D2;
}

.football__bid-end {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    max-width: 1190px;
    height: 326px;
    margin-bottom: 40px;
    font-family: 'Blender Pro';
    font-weight: 250;
    font-size: 64px;
    line-height: 77px;
    color: #FFFFFF;
}

.football__bid-end span {
    position: relative;
    text-align: center;
}

.football__bid-end-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.football__bid-end-logo {
    position: absolute;
}

.football__bid-end-logo-1 {
    top: 2px;
    left: 7%;
}

.football__bid-end-logo-2 {
    top: 38px;
    right: 30%;
}

.football__bid-end-logo-3 {
    top: 9px;
    right: 7%;
}

.football__bid-end-logo-4 {
    bottom: 2px;
    left: 21%;
}

.football__bid-end-logo-5 {
    bottom: 36px;
    right: 32%;
}

.football__forecast {
    position: relative;
    max-width: 1190px;
    min-height: 264px;
    margin-bottom: 60px;
    padding-bottom: 30px;
}

.football__forecast-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.football__forecast-content {
    position: relative;
    padding: 40px 54px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
}

.football__forecast-title {
    margin-top: 20px;
    margin-bottom: 32px;
    font-family: 'Blender Pro';
    font-weight: 250;
    font-size: 64px;
    line-height: 77px;
    color: #FFFFFF;
}

.football__forecast-bottom {
    display: flex;
    align-items: center;
}

.football__forecast-subtitle {
    margin-right: 32px;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
}

.football__forecast-btn {
    position: relative;
    top: 20px;
    width: 250px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    z-index: 2;
}

.football__forecast-label {
    display: flex;
    align-items: center;
    padding-left: 16px;
    width: 190px;
    border: 1px solid #42D0D2;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    background: #000000;
}

.football__image {
    width: 44px;
    height: 44px;
    border-radius: 50%;
}

.football__forecast-input,
.football__forecast-input:focus {
    border: none;
    background: none;
    
    font-weight: 700;
    font-family: "Roboto Condensed";
    color: white;
}

.football__bids {
    position: relative;
    margin-bottom: 40px;
    padding: 40px 54px;
    max-width: 1130px;
    min-height: 417px;
    border: 1px solid #42D0D2;
}

.football__bids-title {
    margin-bottom: 43px;
    font-family: 'Blender Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 77px;
}

.football__bids-empty {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    opacity: 0.3;
}

.football__bids-img {
    position: absolute;
    right: 0;
    bottom: 0;
}

.football__bids-table {
    width: 100%;
    border-collapse: collapse;
}

.football__bids-tbody {
    width: 100%;
    max-height: 500px;
    overflow: auto;
    display: block;
}

.football__bids-thead, .football__bids-tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.football__bids-th {
    width: 33%;
    padding-bottom: 24px;
    font-family: 'Blender Pro';
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
}

.football__bids-td {
    width: 33%;
    padding: 24px 0;
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
}

.football__bids-tr {
    border-bottom: 2px solid #FFFFFF;
}

.football__bids-tr_head {
    border-bottom: 2px solid rgba(255, 255, 255, .4);
}

.football__results {
    max-width: 1130px;
    padding: 40px 54px;
    margin-bottom: 40px;
    border: 1px solid #42D0D2;
}

.football__results-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.football__results-title {
    font-family: 'Blender Pro';
    font-weight: 250;
    font-size: 64px;
    line-height: 77px;
}

.football__results-card {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 380px;
    height: 100px;
}

.football__results-bg {
    position: absolute;
    width: 100%;
    height: 100%;
}

.football__results-left,
.football__results-right {
    position: relative;
}

.football__results-logo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    width: 45px;
    height: 45px;
    border: 2px solid #42D0D2;
    border-radius: 50%;
}

.football__results-logo::before {
    content: "";
    position: absolute;
    left: -1px;
    top: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border: 5px solid #42D0D2;
    border-radius: 50%;
    opacity: 0.5;
}

.football__results-left {
    display: flex;
    align-items: center;
    padding-left: 23px;
}

.football__results-pair {
    margin-bottom: 6px;
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #42D0D2;
}

.football__results-company {
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
}

.football__results-right {
    padding-right: 30px;
}

.football__results-subtitle {
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 12px;
    line-height: 126.3%;
    text-transform: uppercase;
}

.football__results-price {
    font-family: 'Blender Pro';
    font-style: italic;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    color: #42D0D2;
}

.football__results-empty {
    padding-top: 100px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.football__result-subtitle {
    font-family: 'Blender Pro';
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
}

.football__results-time {
    display: flex;
    justify-content: center;
}

.football__results-numb {
    font-family: 'Blender Pro';
    font-weight: 900;
    font-size: 140px;
    line-height: 168px;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #42D0D2;
}

.football__results-dots {
    font-family: 'Blender Pro';
    font-weight: 900;
    font-size: 140px;
    line-height: 168px;
    color: #42D0D2;
}

.football__results-name {
    position: relative;
    top: -20px;
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
}

.football__results-table {
    width: 100%;
    margin-top: 50px;
    border-collapse: collapse;
}

.football__results-tbody {
    width: 100%;
    max-height: 500px;
    overflow: auto;
    display: block;
}

.football__results-thead, .football__results-tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.football__results-th {
    width: 25%;
    padding-bottom: 24px;
    font-family: 'Blender Pro';
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
}

.football__results-td {
    width: 25%;
    padding: 24px 0;
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
}

.football__results-td_star {
    position: relative;
}

.football__results-star {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.football__results-td span {
    position: relative;
    z-index: 2;
}

.football__results-td_blue span {
    color: #42D0D2;
}

.football__results-star_white {
    fill: #fff;
}

.football__results-tr {
    border-bottom: 2px solid #FFFFFF;
}

.football__results-tr_blue {
    background: #42D0D2;
}

.football__results-tr_head {
    border-bottom: 2px solid rgba(255, 255, 255, .4);
}



@media screen and (max-width: 1240px) {
    .football__bids,
    .football__results {
        margin-left: 40px;
        margin-right: 40px;
    }

    .football__bid-end,
    .football__forecast {
        margin-left: 15px;
        margin-right: 15px;
    }
}































.modal-close {
    right: 0;
    cursor: pointer;
}

.main-game__timer-block {
    display: flex;
    align-items: flex-start;
}

.main-game__football-block {
    width: 224px;
    height: 64px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("./../img/share-page/button.png") no-repeat center;
    background-size: 117% 100%;
    color: #fff;
    opacity: 1;
    font-family: "Blender Pro", sans-serif;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.144px;
    text-transform: uppercase;
}

.main-game__football-block_feature {
    margin-left: 35px;
}

.main-game__football-block:hover {
    color: #fff;
}

.main-game__football-img {
    margin-right: 8px;
    display: block;
}

@media screen and (max-width: 1430px) {
    .main-game__timer-block {
        flex-direction: column-reverse;
    }

    .main-game__football-block {
        margin-top: 0;
        margin-bottom: 15px;
    }

    .main-game__football-block_feature {
        margin-left: 0;
    }
}

@media screen and (max-width: 992px) {
    .main-game__timer-block {
        flex-direction: row;
    }

    .main-game__football-block {
        margin-top: 40px;
        margin-bottom: 0px;
    }

    .main-game__football-block_feature {
        margin-left: 35px;
    }
}

@media screen and (max-width: 718px) {
    .main-game__timer-block {
        flex-direction: column;
    }

    .main-game__football-block {
        margin-top: 0;
        margin-bottom: 15px;
    }

    .main-game__football-block_feature {
        margin-left: 0;
    }
}





@media screen and (max-width: 980px) {
    .football_main-content {
        flex-direction: column-reverse;
    }

    .football__main-left {
        padding-top: 30px;
    }

    .football__title p{
        font-size: 67px;
        line-height: 77px;
    }

    .football__main-right {
        width: 100%;
        padding-top: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .football__time-text {
        margin-left: 25px;
    }

    .football__img {
        /* width: 170px; */
        margin-bottom: 0;
    }

    .football__time-title {
        margin-top: 10px;
        margin-bottom: 0;
    }

    .football__time-block {
        width: 70px;
    }

    .football__instruction-block {
        position: relative;
    }

    .football__instruction-left {
        position: absolute;
        top: 0;
        left: -35px;
    }

    .football__container {
        padding: 0 74px;
    }

    .football__bottom-text p {
        margin-bottom: 40px;
        font-size: 36px;
        line-height: 43px;
    }
}


@media screen and (max-width: 500px) {
    .football__container {
        padding: 0 20px;
    }

    .football__main {
        margin-bottom: 33px;
    }

    .football__img {
        width: 100px;
    }

    .football__time-title {
        display: none;
    }

    .football__time-block {
        width: 48px;
    }

    .football__time-numb {
        font-size: 40px;
        line-height: 48px;
    }

    .football__time-name {
        font-size: 10px;
        line-height: 12px;
    }

    .football__title p{
        font-size: 36px;
        line-height: 42px;
    }

    .football__prize-title p {
        font-size: 12px;
        line-height: 14px;
    }

    .football__prize-amount p{
        margin-bottom: 24px;
        font-size: 28px;
        line-height: 34px;
    }

    .football__button p {
        margin-bottom: 24px;
        font-size: 28px;
        line-height: 34px;
        width: 100%;
        background-size: 112%;
    }

    .football__modal-call {
        font-size: 13px;
        line-height: 28px;
    }

    .football__subtitle p {
        margin-bottom: 32px;
        font-size: 18px;
        line-height: 22px;
    }

    .football__instruction {
        margin-bottom: 50px;
    }

    .football__instruction-block {
        margin-bottom: 30px;
    }

    .football__instruction-left {
        position: static;
    }

    .football__instruction-img {
        display: none;
    }

    .football__instruction-text {
        font-size: 14px;
        line-height: 20px;
    }

    .football__instruction-link {
        margin-left: 0;
    }

    .football__instruction-line {
        width: 136px;
    }

    .football__bottom-text p {
        font-size: 26px;
        line-height: 31px;
    }

    .football__modal-window {
        padding: 20px;
    }

}

.main-game__football-block_mobile {
    margin-left: 0;
    margin-top: 0;
}

.dragon__window {
   
}

.dragon__title {
    margin-bottom: 15px;
    max-width: none;
}

.dragon__subtitle {
    margin-bottom: 24px;
    font-family: 'Blender Pro';
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.112px;
}

.dragon__img {
    margin-bottom: 27px;
    width: 100%;
}

.dragon__text {
    display: flex;

    font-family: 'Blender Pro';
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    letter-spacing: -0.112px;
}

.dragon__index {
    margin-right: 7px;
    white-space: nowrap;
}

.dragon__line {
    margin: 12px 0;
    width: 135px;
}

.dragon__btn {
    margin: 0;
    margin-top: 30px;
    padding-left: 35px;
    padding-right: 35px;
    width: auto;
    min-width: 250px;
    background-size: 112% 100%;
}







@media screen and (max-width: 1024px) {
    .football__img {
        width: 240px;
    }

    .football__forecast {
        padding: 0;
        padding-bottom: 40px;
    }

    .football__forecast-bottom {
        flex-direction: column;
        align-items: flex-start;
    }

    .football__forecast-subtitle {
        margin-bottom: 20px;
    }

    .football__bid-end,
    .football__forecast {
        margin-right: 57px;
        margin-left: 57px;
    }

    .football__bids,
    .football__results {
        margin-right: 71px;
        margin-left: 71px;
        padding: 20px;
    }

    .football__bids-img {
        width: 400px;
    }

    .football__results-top {
        flex-direction: column;
        align-items: flex-start;
    }

    .football__results-card {
        margin-top: 10px;
        transform: scale(0.8) translateX(-50px);
    }
}

@media screen and (max-width: 768px) {
    .football__main-right {
        flex-direction: column;
        margin-left: 0;
    }

    .football__img {
        width: 100%;
        margin-bottom: 16px;
    }

    .football__forecast-title {
        margin-bottom: 18px;
        font-size: 36px;
        line-height: 43px;
    }

    .football__bid-end span {
        font-size: 36px;
        line-height: 43px;
    }

    .football__bid-end-logo {
        transform: scale(0.7);
    }

    .football__bid-end,
    .football__forecast {
        margin-left: 5px;
        margin-right: 5px;
    }

    .football__forecast-content {
        padding-left: 30px;
        padding-right: 30px;
    }

    .football__forecast-subtitle {
        margin-right: 0;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
    }

    .football__forecast-label {
        width: 100%;
    }

    .football__bids,
    .football__results {
        margin-right: 20px;
        margin-left: 20px;
    }

    .football__bids-img {
        width: 300px;
    }

    .football__bids {
        min-height: 350px;
    }

    .football__bids-td,
    .football__results-td {
        display: table-cell;
    }

    .football__bids-title {
        font-size: 36px;
        line-height: 43px;
    }

    .football__bids-empty {
        font-size: 16px;
        line-height: 19px;
    }

    .football__bids-th,
    .football__bids-td {
        width: 10%;
    }

    .football__results-numb  {
        font-size: 100px;
        line-height: 140px;
    }

    .football__results-title {
        font-size: 36px;
        line-height: 43px;
    }

    .football__results-dots {
        font-size: 100px;
        line-height: 140px;
    }

    .football__results-numb,
    .football__results-dots {
        font-size: 48px;
        line-height: 90px;
    }

    .football__results-empty {
        padding: 30px 0;
    }

    .football__result-subtitle {
        text-align: center;
        font-size: 18px;
        line-height: 20px;
    }

    .football__results-name {
        font-size: 12px;
        line-height: 13px;
    }

    .football__results-card {
        transform: scale(0.6) translateX(-124px);
    }
}