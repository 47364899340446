.home__container {
    max-width: 966px;
    width: 100%;
    margin: 0 auto;
    color: #fff;
    font-family: "Blender Pro", sans-serif;
}

.home__main {
    max-width: 633px;
    padding-bottom: 50px;
    color: #fff;
}

.home__description {
    width: 100%;
    margin-bottom: 49px;
    background-clip: padding-box;
}

.home__description-content {
    padding: 0 57px 0 25px;
    background: #42D0D2;
    box-shadow: 0 0 30px rgba(66, 208, 210, 0.6);
    font-family: "Roboto", sans-serif;
    font-size: 14px;
}

.home__description-top {
    width: 100%;
    height: 0;
    border-bottom: 31px solid #42D0D2;
    border-right: 25px solid transparent;
    filter: drop-shadow(0 0 30px rgba(66, 208, 210, 0.6));
}

.home__description-bottom {
    width: 100%;
    height: 25px;
    border-top: 29px solid #42D0D2;
    border-left: 25px solid transparent;
    filter: drop-shadow(0 0 30px rgba(66, 208, 210, 0.6));
}

.home__description_bold {
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Blender Pro", sans-serif;
}

.home__title {
    margin-left: 5px;
    font-size: 45px;
    line-height: 148.4%;
}


.home__block-top {
    display: flex;
    align-items: flex-start;
}

.home__block-left {
    position: relative;
    min-width: 68px;
    height: 89px;
    margin-right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("./../img/home/number-bg.png") no-repeat center;
    background-size: 100% 100%;
    font-size: 44px;
    font-style: italic;
}

.home__ring {
    position: absolute;
    right: 0;
    bottom: 0;
    animation: rotation 3s linear infinite;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.home__block {
    margin-bottom: 57px;
}

.home__block-title {
    margin-bottom: 4px;
    font-style: italic;
    font-weight: bold;
    font-size: 29px;
    line-height: 148.4%;
    text-transform: uppercase;
}

.home__block-text {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 148.4%;
}

.home__img-1 {
    margin-top: 29px;
    filter: drop-shadow(0 0 30px rgba(66, 208, 210, 0.6));
}

.home__img-2 {
    margin-top: 37px;
    filter: drop-shadow(0 0 30px rgba(66, 208, 210, 0.6));
}

.home__img-3 {
    margin-top: 32px;
    filter: drop-shadow(0 0 30px rgba(66, 208, 210, 0.6));
}

.home__img-4 {
    margin-top: 8px;
}

.home__img-5 {
    margin-top: 30px;
    filter: drop-shadow(0 0 30px rgba(66, 208, 210, 0.6));
}

.home__also {
    position: relative;
    width: 100%;
    height: 112px;
    margin-top: 110px;
    margin-bottom: 114px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("./../img/home/btn-also.png") no-repeat center;
    background-size: 100% 100%;
    font-style: italic;
    font-weight: bold;
    font-size: 54px;
    line-height: 148.4%;
}

.home__also-angle {
    position: absolute;
    width: 62px;
    height: 21px;
    background: url("./../img/home/btn-also-angle.png") no-repeat center;
}

.home__also-angle_left {
    top: -8px;
    left: -10px;
    transform: rotate(180deg);
}

.home__also-angle_right {
    right: -7px;
    bottom: -8px;
}

.home__also-dot {
    position: absolute;
    width: 51px;
    height: 51px;
    background: url("./../img/home/btn-also-dots.png") no-repeat center;
}

.home__also-dot_left {
    left: -36px;
    bottom: -13px;
}

.home__also-dot_right {
    top: 10px;
    right: 10px;
}

.home__end {
    max-width: 409px;
    margin-bottom: 70px;
    font-size: 45px;
    line-height: 110%;
    text-transform: uppercase;
}


















@media screen and (max-width: 1000px) {
    .home__container {
        max-width: 376px;
        margin-left: 74px;
    }

    .home__title {
        margin-bottom: 30px;
    }

    .home__description-content {
        padding-right: 20px;
    }
}

@media screen and (max-width: 550px) {
    .home__container {
        max-width: none;
        margin-left: 0;
    }

    .home__title {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 490px) {
    .home__buttons {
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
}
